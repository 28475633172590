import React, { useState, useEffect } from 'react';

const ClockItem = (props) => {
    const { startTime, endTime } = props;
    const [auctionStatus, setAuctionStatus] = useState(false);
    const [auctionStatusMessage, setAuctionStatusMessage] = useState('')
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (startTime && endTime) setNewTime();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const setNewTime = () => {
        const currentTimestamp = new Date().getTime()
        let countdownDate = 0;
        if (startTime * 1000 > currentTimestamp) {
            setAuctionStatusMessage('Auction has not started')
            setAuctionStatus(false)
        } else if (endTime * 1000 > currentTimestamp) {
            setAuctionStatus(true)
            countdownDate = endTime * 1000;
            setAuctionStatusMessage('Auction is started')
        } else {
            setAuctionStatusMessage('Auction has ended')
            setAuctionStatus(false)
        }
        if (countdownDate) {
            const distanceToDate = countdownDate - currentTimestamp;
            let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
            let hours = Math.floor(
                (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            let minutes = Math.floor(
                (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
            );
            let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

            if (numbersToAddZeroTo.includes(days)) {
                days = `0${days}`;
            }
            if (numbersToAddZeroTo.includes(hours)) {
                hours = `0${hours}`;
            }
            if (numbersToAddZeroTo.includes(minutes)) {
                minutes = `0${minutes}`;
            }
            if (numbersToAddZeroTo.includes(seconds)) {
                seconds = `0${seconds}`;
            }
            setState({ days: days, hours: hours, minutes: minutes, seconds: seconds });
        }
    };


    return (
        <div className="card no-hover countdown-times my-4">
            {auctionStatus ?
                <div className="countdown d-flex justify-content-center">
                    <div className="countdown-container days">
                        <span className="countdown-heading days-top">Days</span><span className="countdown-value days-bottom">{state.days}</span>
                    </div>
                    <div className="countdown-container hours">
                        <span className="countdown-heading hours-top">Hours</span><span className="countdown-value hours-bottom">{state.hours}</span>
                    </div>
                    <div className="countdown-container minutes">
                        <span className="countdown-heading minutes-top">Minutes</span><span className="countdown-value minutes-bottom">{state.minutes}</span>
                    </div>
                    <div className="countdown-container seconds">
                        <span className="countdown-heading seconds-top">Seconds</span><span className="countdown-value seconds-bottom">{state.seconds}</span>
                    </div>
                </div>
                : <div className="countdown d-flex justify-content-center">
                    <p style={{textTransform: 'none'}}>{auctionStatusMessage}</p>
                </div>
            }

        </div>
    );
}

export default ClockItem;