import React from 'react';

import Hero from '../components/Hero/Hero';
import AuctionsHome from '../components/Auctions/AuctionsHome';
import TopSeller from '../components/TopSeller/TopSellerHome';
import Collections from '../components/Collections/Collections';
import ExploreHome from '../components/Explore/ExploreHome';
import Work from '../components/Work/Work';

const Home = (props) => {
    return (
        <div>
            <Hero />
            <Collections />
            <ExploreHome />
            <AuctionsHome />
            <TopSeller />
            <Work />
        </div>
    );
}

export default Home;