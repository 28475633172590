import React from 'react';

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ExploreCollections from '../components/Explore/ExploreCollections';

const Collections = (props) => {
    return (
        <div>
            <Breadcrumb title="Explore Collections" subpage="Explore" page="Explore Style 3" />
            <ExploreCollections />
        </div>
    );
}

export default Collections;