import styled from 'styled-components';
import {PlayCircleOutline} from "@styled-icons/material";
import {MusicNote} from "@styled-icons/material/MusicNote";
import {Image} from "@styled-icons/feather/Image";

export const coverStyle = {
    height: "200px",
    width: "100%",
    borderRadius: "6px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: 'cover'
}
export const VideoIcon = styled(PlayCircleOutline)`
`;
export const AudioIcon = styled(MusicNote)`
`;
export const ImageIcon = styled(Image)`
`;
export const typeIcon = {
    position: 'absolute',
    top: 10,
    left: 10,
    background: '#451CCEd9',
    height: 28,
    width: 28,
    textAlign: 'center',
    borderRadius: 10,
    border: '1px solid rgba(255,255,255,0.6)'
}
export const auctionTime = {
    position: 'absolute',
    right: 10,
    top: 10,
    // background: '#451CCEd9',
    height: 28,
    color: '#fff',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    border: '1px solid rgba(255,255,255,0.5)',
    fontSize: '0.7rem'
}