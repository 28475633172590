import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import NFT from "../Item/NFT";

const AuctionsHome = () => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios.get(`/item?saleType=auction&page=1&pageLimit=8`)
            .then(res => {
                setItems(res.data.items);
            }).catch(e => {
                console.log(e);
                setItems([]);
            })
    }, []);

    return (
        <section className="live-auctions-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro d-flex justify-content-between align-items-end m-0">
                            <div className="intro-content">
                                <span>Auctions</span>
                                <h3 className="mt-3 mb-0">Live Auctions</h3>
                            </div>
                            <div className="intro-btn mb-2">
                                <Link className="btn content-btn" to="/explore/live-auctions">View All</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="auctions-slides">
                    <div className="row items">
                        {/* Single Slide */}
                        {items.map((item, idx) => {
                            return (
                                <div key={`auct_${idx}`} className="col-12 col-sm-6 col-md-4 col-lg-3 item">
                                    <NFT item={item} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AuctionsHome;
