import React from 'react';

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Creates from '../components/Create/Create';

const Create = (props) => {

    return (
        <div className="main">
            <Breadcrumb title="Create" subpage="Pages" page="Create" />
            <Creates {...props} />
        </div>
    );
}

export default Create;