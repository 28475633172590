import { ethers } from "ethers";
import { getCollectionContract, getContractInfo, getContractObj } from ".";

export function isAddress(address) {
    try {
        ethers.getAddress(address);
    } catch (e) { return false; }
    return true;
}

export function toEth(amount) {
    return ethers.formatEther(String(amount), { commify: true });
}

export function toWei(amount) {
    return ethers.parseEther(String(amount));
}
/**
 * Governance Token Contract Management
 */
export async function getTokenBalance(account, tokenName, tokenDecimal, chainId, library) {
    if (tokenName === 'main') {
        const balance = await library.getBalance(account);
        return ethers.formatEther(balance);
    } else {
        console.log("token balance ...");
        const Token = getContractObj(tokenName, chainId, library.getSigner());
        if (Token) {
            const balance = await Token.balanceOf(account);
            return ethers.formatUnits(balance, tokenDecimal);
        }
    }
    return 0;
}
export async function isTokenApprovedForMarket(account, amount, chainId, provider) {
    const marketContract = getContractObj('NationMarket', chainId, provider);
    const tokenContract = getContractObj('GovernanceToken', chainId, provider);

    const allowance = await tokenContract.allowance(account, marketContract.address);
    if (BigInt(toWei(amount)) > BigInt(allowance)) {
        return false;
    }
    return true;
}
export async function isTokenApprovedForAuction(account, amount, chainId, provider) {
    const auctionContract = getContractObj('NationAuction', chainId, provider);
    const tokenContract = getContractObj('GovernanceToken', chainId, provider);

    const allowance = await tokenContract.allowance(account, auctionContract.address);
    if (BigInt(toWei(amount)) > BigInt(allowance)) {
        return false;
    }
    return true;
}
export async function approveTokenForMarket(chainId, signer) {
    const marketContract = getContractObj('NationMarket', chainId, signer);
    const tokenContract = getContractObj('GovernanceToken', chainId, signer);

    const approveAmount = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
    try {
        const approve_tx = await tokenContract.approve(marketContract.address, approveAmount);
        await approve_tx.wait(1);
        return true;
    } catch (e) {
        console.log(e)
        return false;
    }
}
export async function approveTokenForAuction(chainId, signer) {
    const auctionContract = getContractObj('NationAuction', chainId, signer);
    const tokenContract = getContractObj('GovernanceToken', chainId, signer);

    const approveAmount = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
    try {
        const approve_tx = await tokenContract.approve(auctionContract.address, approveAmount);
        await approve_tx.wait(1);
        return true;
    } catch (e) {
        console.log(e)
        return false;
    }
}
/**
 * NFT Factory Management
 */
export async function createNewCollection(name, symbol, uri, royalty, bPublic, chainId, provider, type) {
    const factoryContract = getContractObj('NationFactory', chainId, provider)
    const factoryContractInfo = getContractInfo('NationFactory', chainId)
    const factoryInterface = new ethers.Interface(factoryContractInfo.abi)
    const proxyContract = getContractObj('NationProxy', chainId, provider)
    const serviceFee = BigInt(15 * 10 ** 18)

    // Appease the linter, but bigger problems if it fails here
    if (!factoryContract || !proxyContract) {
        console.error('Smart Contract(s) not found!')

        return
    }

    try {
        const tx = await proxyContract.handleFeeAndMint(type, name, symbol, uri, royalty, bPublic, { value: serviceFee })

        console.log('tx:', tx)

        let receipt = await tx.wait(2)
        console.log('receipt:', receipt)

        if (receipt && receipt.logs) {
            const logs = receipt.logs
            let collectionAddress = ""
            for (let index = 0; index < logs.length; index++) {
                if (factoryContractInfo.address.toLowerCase() === logs[index].address.toLowerCase()) {

                    collectionAddress = factoryInterface.parseLog(logs[index])?.args?.collection_address.toLowerCase()

                    console.log('New Collection Address: ', collectionAddress)
                    return collectionAddress
                }
            }
        }

        return false
    } catch (error) {
        console.error(error)

        return false
    }
}
/**
 * NFT Contract Management
 */
export async function isNFTApprovedForMarket(collection, account, chainId, provider, type) {
    const marketContractInfo = getContractInfo('NationMarket', chainId);
    const nftToken = getCollectionContract(collection, chainId, provider, type);


    return await nftToken.isApprovedForAll(account, marketContractInfo.address);
}
export async function isNFTApprovedForAuction(collection, account, chainId, provider, type) {
    const auctionContractInfo = getContractInfo('NationAuction', chainId);
    const nftToken = getCollectionContract(collection, chainId, provider, type);

    return await nftToken.isApprovedForAll(account, auctionContractInfo.address);
}
export async function setNFTApprovalForMarket(collection, approved, chainId, provider, type) {
    const marketContractInfo = getContractInfo('NationMarket', chainId);
    const nftToken = getCollectionContract(collection, chainId, provider, type);
    try {
        const tx = await nftToken.setApprovalForAll(marketContractInfo.address, approved);
        await tx.wait(1);
        return true;
    } catch (e) {
        console.log(e)
    }
    return false;
}
export async function setNFTApprovalForAuction(collection, approved, chainId, provider, type) {
    const auctionContractInfo = getContractInfo('NationAuction', chainId);
    const nftToken = getCollectionContract(collection, chainId, provider, type);
    try {
        const tx = await nftToken.setApprovalForAll(auctionContractInfo.address, approved);
        await tx.wait(1);
        return true;
    } catch (e) {
        console.log(e)
    }
    return false;
}
export async function addItem(collection, uri, amount, royalty, chainId, provider, type) {
    const collectionContract = getCollectionContract(collection, chainId, provider, type);
    try {
        let tx;
        if (type === 1) {
            tx = await collectionContract.addItem(uri, royalty);
        } else {
            tx = await collectionContract.addItem(uri, amount, royalty);
        }
        await tx.wait(1);
        return true;
    } catch (e) {
        console.log(e)
        return false;
    }
}
/**
 * Market Contract Management
 */
export async function listItem(collection, owner, token_id, amount, price, chainId, provider, type) {
    const marketContract = getContractObj('NationMarket', chainId, provider);

    if (!marketContract) return false
    try {
        let isApproved = await isNFTApprovedForMarket(collection, owner, chainId, provider, type);
        if (!isApproved) {
            isApproved = await setNFTApprovalForMarket(collection, true, chainId, provider, type);
        }
        if (isApproved) {
            const tx = await marketContract.list(collection, token_id, amount, ethers.parseEther(price));
            await tx.wait(1);
            return true;
        }
        return false;
    } catch (e) {
        console.log(e);
        return false;
    }
}
export async function delistItem(id, chainId, provider) {
    const marketContract = getContractObj('NationMarket', chainId, provider);
    if (!marketContract) return false
    try {
        const tx = await marketContract.delist(id)
        await tx.wait(1)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}
export async function buy(marketAddress, id, amount, price, chainId, provider) {
    console.log('marketAddress', marketAddress)

    const marketContractInfo = getContractInfo('NationMarket', chainId);
    const marketContract = new ethers.Contract(marketAddress, marketContractInfo.abi, provider);

    if (!marketContract) return false;

    try {
        const tx = await marketContract.buy(id, amount, { value: price });
        await tx.wait(2)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}
/**
 * Auction Contract Management
 */
export async function createAuction(collection, owner, token_id, amount, startPrice, startTime, endTime, chainId, provider, type) {
    const auctionContract = getContractObj('NationAuction', chainId, provider);
    const auctionContractInfo = getContractInfo('NationAuction', chainId);
    if (!auctionContract || !auctionContractInfo) return false
    try {
        let isApproved = await isNFTApprovedForAuction(collection, owner, chainId, provider, type);
        if (!isApproved) {
            isApproved = await setNFTApprovalForAuction(collection, true, chainId, provider, type);
        }
        if (isApproved) {
            const tx = await auctionContract.createAuction(collection, token_id, amount, ethers.parseEther(startPrice), startTime, endTime);
            const receipt = await tx.wait(1);
            if (receipt.confirmations) {
                return true
            }
        }
        return false;
    } catch (e) {
        console.log(e);
        return false;
    }
}
export async function finalizeAuction(id, chainId, provider) {
    const auctionContract = getContractObj('NationAuction', chainId, provider)
    if (!auctionContract) return false
    try {
        const tx = await auctionContract.finalizeAuction(id)
        await tx.wait(1)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}
export async function bidOnAuction(id, price, value, chainId, provider) {
    const auctionContract = getContractObj('NationAuction', chainId, provider)
    if (!auctionContract) return false;
    try {
        console.log('price', price)
        const tx = await auctionContract.bidOnAuction(id, price, { value: value });
        await tx.wait(1);
        return true
    } catch (e) {
        console.log("bidOnAuction: ", e);
        return false
    }
}
