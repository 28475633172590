import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { useParams } from 'react-router'
import AuthorProfile from '../AuthorProfile/AuthorProfile'
import NFT from "../Item/NFT"
import { getContractInfo } from '../../utils'

const Author = (props) => {
    const [curTab, setCurTab] = useState('onsale')
    const { user, login } = props
    let { address } = useParams()
    const [authorProfile, setAuthorProfile] = useState(null)
    const { account, library } = useWeb3React()

    const [saleItems, setSaleItems] = useState([])
    const [ownedItems, setOwnedItems] = useState([])
    const [createdItems, setCreatedItems] = useState([])
    const [pageSale, setPageSale] = useState(1)
    const [pageOwned, setPageOwned] = useState(1)
    const [pageCreated, setPageCreated] = useState(1)
    const [noItems, setNoItems] = useState(false)
    const [loading, setLoading] = useState(false)
    const [initialItemsLoaded, setInitialItemsLoaded] = useState(true)

    useEffect(() => {
        axios.get(`/user/${address ? address : ""}`)
            .then(res => {
                setAuthorProfile(res.data.user)
            })
    }, [address])

    useEffect(() => {
        if (!!user) {
            login()
        }
    }, [user, account, library])

    useEffect(() => {
        if (address) {
            let pageName = curTab || 'onsale'
            let page = 1
            if (pageName === 'owned') page = pageOwned
            else if (pageName === 'created') page = pageCreated
            else page = pageSale
            fetchItems(pageName, page)
        }
    }, [curTab, address, pageSale, pageOwned, pageCreated])

    const fetchItems = (name, page) => {
        let query = `/item/?`
        switch (name) {
            case 'created':
                query += `creator=${address}&page=${page}`
                break
            case 'owned':
                query += `owner=${address}&page=${page}`
                break
            case 'onsale':
            default:
                query += `owner=${address}&page=${page}&onsale=true`
                break
        }
        setLoading(true)
        // console.log(name, ' => ', query);
        axios.get(query)
            .then(res => {
                setLoading(false)
                if (name === 'owned') {
                    initialItemsLoaded ? setOwnedItems(res.data.items) : setOwnedItems(ownedItems.concat(res.data.items))
                } else if (name === 'created') {
                    initialItemsLoaded ? setCreatedItems(res.data.items) : setCreatedItems(createdItems.concat(res.data.items))
                } else {
                    initialItemsLoaded ? setSaleItems(res.data.items) : setSaleItems(saleItems.concat(res.data.items))
                }
                if (res.data.items.length === 0) setNoItems(true)
            }).catch(err => {
                setLoading(false)
                if (name === 'owned') setOwnedItems([...ownedItems])
                else if (name === 'created') setCreatedItems([...createdItems])
                else setSaleItems([...saleItems])
                setNoItems(true)
                console.log(err)
            })
    }

    function loadMore() {
        if (!loading) {
            setInitialItemsLoaded(false)
            if (curTab === 'owned') setPageOwned(pageOwned + 1)
            else if (curTab === 'created') setPageCreated(pageCreated + 1)
            else setPageSale(pageSale + 1)
        }
    }

    const tabClick = (tabName) => {
        setInitialItemsLoaded(true)
        setCurTab(tabName)
        setNoItems(false)
        if (tabName === 'owned') setPageOwned(1)
        else if (tabName === 'created') setPageCreated(1)
        else setPageSale(1)
    }

    return (
        <section className="author-area explore-area popular-collections-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12">
                        <AuthorProfile author={authorProfile} account={account} />
                    </div>
                    <div className="col-12">
                        <div className="row justify-content-center text-center">
                            <div className="col-12">
                                <div className="explore-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mt-4" data-toggle="buttons">
                                    <label className="btn active d-table text-uppercase" onClick={() => tabClick('onsale')}>
                                        <input type="radio" defaultValue="onsale" defaultChecked className="explore-btn" />
                                        <span>On Sale</span>
                                    </label>
                                    <label className="btn d-table text-uppercase" onClick={() => tabClick('owned')}>
                                        <input type="radio" defaultValue="owned" className="explore-btn" />
                                        <span>Owned</span>
                                    </label>
                                    <label className="btn d-table text-uppercase" onClick={() => tabClick('created')}>
                                        <input type="radio" defaultValue="created" className="explore-btn" />
                                        <span>Created</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {
                            curTab === 'owned' ?
                                <div className="row items explore-items" id='account_owned'>
                                    {!ownedItems.length && (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 item explore-item">
                                            <p style={{ textAlign: 'center' }}>Items not found</p>
                                        </div>
                                    )}
                                    {
                                        ownedItems.map((item, index) => (
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 item explore-item" key={index}>
                                                <NFT item={item} action={'owned'} />
                                            </div>
                                        ))
                                    }
                                </div>
                                : (curTab === 'created' ?
                                    <div className="row items explore-items" id='account_created'>
                                        {!createdItems.length && (
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 item explore-item">
                                                <p style={{ textAlign: 'center' }}>Items not found</p>
                                            </div>
                                        )}
                                        {
                                            createdItems.map((item, index) => (
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 item explore-item" key={index}>
                                                    <NFT item={item} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className="row items explore-items" id='account_onsale'>
                                        {!saleItems.length && (
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 item explore-item">
                                                <p style={{ textAlign: 'center' }}>Items not found</p>
                                            </div>
                                        )}
                                        {
                                            saleItems.map((item, index) => (
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 item explore-item" key={index}>
                                                    <NFT item={item} action={'onsale'} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                        }
                        <div className="load-more" style={{ display: noItems ? "none" : "", textAlign: 'center' }}>
                            <button onClick={() => loadMore()} className="btn mt-3 mt-sm-4">
                                {loading ? "Loading..." : "Load more"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Author
