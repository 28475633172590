import styled from 'styled-components';
import {PlusCircle} from "@styled-icons/feather";

export const UploadField = styled.div`
    margin-bottom: 20px;
`;
export const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: dotted 1px grey;
    border-radius: 12px;
    height: 280px;
`;
export const UploadCaption = styled.div`
    font-size: 14px;
    color: grey;
`;
export const label = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    span{
        color: grey;
        font-size: 14px;
    }
`;
export const ChooseFileBtn = styled.div`
  display: inline-block;
    position: relative;
    padding: 10px 20px;
    background: #e9e9e9;
    border-radius: 20px;
    margin-top: 20px;
    color: #1f1f1f;
    font-size: 14px;
    font-weight: bold;
`;
export const FileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
`;
export const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: dotted 1px grey;
    border-radius: 12px;
    height: 280px;
`;
export const MediaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 160px;
`;
export const CloseIconContainer = styled.div`
    width: 100%;
    margin-left: -5px;
    margin-top: 5px;
    text-align: right;
`;
export const ImagePreview = styled.img`
  border-radius: 6px;
  max-width: 80%;
  max-height: 100%;
`;
export const VideoPreview = styled.video`
  border-radius: 6px;
  max-width: 80%;
  max-height: 100%;
`;
export const AudioPreview = styled.audio`
  border-radius: 6px;
  max-width: 80%;
  max-height: 100%;
`;
export const Option = styled.div`
    margin-top: 4px;
    font-size : 14px;
`;
export const CollectionIcon = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 24px;
`;
export const CollectionName = styled.div`
    // color: #1E2026;
    font-weight: bold;
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
`;
export const CollectionType = styled.div`
    color: grey;
    font-size: 14px;
`;
export const CollectionPlusIcon = styled(PlusCircle)`
`;
export const Collections = styled.div`
    display: flex;
    flex-flow: wrap;
`;
export const SelectCollection = styled.div`
    margin: 20px 0;
`;
export const collection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border: solid 1px #cacaca;
    border-radius: 12px;
    margin: 8px;
    cursor: pointer;
    &.active {
        border: solid 1px #a754da;
        background: #fafafa;
    }
    &:hover {
        background: #eee;
        border: 1px solid #57048a;
    }
`;
export const Field = styled.div`
    margin: 20px 20px;
`;
export const Input = styled.input`
    width: 100%;
    border: unset;
    border-bottom: 1px solid rgb(234, 236, 239);
    font-size: 16px;
    padding: 8px;
    &:focus-visible{
        outline: unset;
    }
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
export const ModalAction = styled.div`
  width: 100%;
  text-align: center;
`;
export const ModalButton = styled.div`
     padding: 12px 40px;
    background: #e24717;
    font-size: 20px;
    color: white;
    border-radius: 24px;
    cursor: pointer;
`;
export const ModalBody = styled.div`
  padding: 8px 12px;
`;
export const ModalTitle = styled.div`
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 24px;
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

// More Custom Styles
export const PropertyAction = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
export const PropertyActionWrap = styled.div`
    width: 50px;
    heigh: 50px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #d3d3d3;
`
