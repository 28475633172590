import { formatUnits } from "ethers";


export function prettyFormat(value, unitName, points) {
  let p = points || 8;
  return +((+formatUnits(value, unitName)).toFixed(p));
}

export function getShortAddress(address) {
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
}

export function getUserName(obj) {
  let name = obj.name;
  if (!name) {
    if (!obj.address) {
      return obj;
    }
    name = getShortAddress(obj.address);
  }
  return name
}
