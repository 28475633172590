import axios from "axios";
import { IPFS_URL, PINATA_JWT_TOKEN } from "./constants";

export const ipfsBaseURL = IPFS_URL;

export const getIpfshash = async (data) => {
  let config = {
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${PINATA_JWT_TOKEN}`,
    },
    data: JSON.stringify(data)
  };
  const res = await axios(config);
  return res.data.IpfsHash;
}

export const getIpfsHashFromFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file);

  let config = {
    maxBodyLength: "Infinity",
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      'Authorization': `Bearer ${PINATA_JWT_TOKEN}`,
    },
  };
  const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, config);
  return res.data.IpfsHash;
};