import React from 'react';
import { Link } from "react-router-dom";
import * as CS from '../../custom/componentStyle';
import approvedBadge from '../../assets/img/approvedBadge.svg';
import DefaultNFTLogo from '../../assets/img/kaikas.png';

const CollectionItem = (props) => {
    const { collection } = props;
    return (
        <>
            {collection &&
                <div className="card no-hover text-center">
                    <div className="image-over">
                        <Link to={`/collections/${collection.address}`}>
                            <img className="card-img-top" src={collection.image} alt="" style={CS.coverStyle}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = DefaultNFTLogo;
                                }}
                            />
                        </Link>
                        {/* Seller */}
                        <Link className="seller" to={`/account/${collection.owner}`}>
                            <div className="seller-thumb avatar-lg">
                                <img className="rounded-circle" src={collection.ownerUser.profilePic} alt="" />
                                {(collection.ownerUser.isApproved && (
                                    <img className="seller-verification" src={approvedBadge} alt="approved" />
                                ))}
                            </div>
                        </Link>
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                        {/* Card Body */}
                        <div className="card-body mt-4">
                            <Link to={`/collections/${collection.address}`}>
                                <h5 className="mb-2">{collection.name}</h5>
                            </Link>
                            {/* <span>ERC-721</span> */}
                            <div style={{ fontSize: 12, display: 'flex', justifyContent: 'space-between' }}>
                                <div>Royalty: {collection.royalty / 10 || 0} %</div>
                                <div>ERC-721</div>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>

    );
}

export default CollectionItem;
