import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import approvedBadge from '../../assets/img/approvedBadge.svg';

let coverStyle = {
    height: "200px",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
}

const AuthorProfile = (props) => {
    const { author, account } = props;
    const [addrValid, setAddresValid] = useState(false);
    const [isProfile, setIsProfile] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };
    const handleCopy = () => {
        setSnackBarMessage("Copied!");
        setOpenSnackbar(true);
    };

    useEffect(() => {
        if (author?.address) {
            let authorAddr = author.address.toLowerCase();
            if (ethers.isAddress(authorAddr)) {
                setAddresValid(true);
                if (authorAddr === account?.toLowerCase()) setIsProfile(true);
                else setIsProfile(false);

            } else {
                setAddresValid(false);
            }
        }
    }, [author, account]);

    return (
        <>
            {addrValid &&
                <div className="card no-hover text-center author-profile">
                    <div className="image-over">
                        {/* TODO set profile cover position */}
                        <div className="card-img-top" style={{ backgroundImage: `url(${author.profileCover})`, ...coverStyle }}></div>

                        <div className="author">
                            <div className="author-thumb avatar-lg">
                                <img className="rounded-circle" src={`${author.profilePic}`} alt="" />
                            </div>
                        </div>
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                        {/* Card Body */}
                        <div className="card-body mt-4">
                            <div className="mb-3 profile-header">
                                <h4 className="m-0">
                                    {`${author.name}`}
                                </h4>
                                &nbsp;
                                {(author.isApproved && (
                                    <img src={approvedBadge} alt="approved" />
                                ))}
                            </div>
                            <p className="my-3">{`${author.bio || ''}`}</p>
                            <div className="input-group">
                                <input type="text" className="form-control" value={author.address || ''} readOnly style={{ textAlign: 'center' }} />
                                <div className="input-group-append">
                                    <CopyToClipboard text={author.address || ''}
                                        onCopy={() => handleCopy()}>
                                        <button><i className="icon-docs" /></button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            {/* Social Icons */}
                            <div className="social-icons d-flex justify-content-center my-3">
                                {author.facebookLink && <a className="facebook" href="#">
                                    <i className="fab fa-facebook-f" />
                                    <i className="fab fa-facebook-f" />
                                </a>}
                                {author.twitterLink && <a className="twitter" href="#">
                                    <i className="fab fa-twitter" />
                                    <i className="fab fa-twitter" />
                                </a>}
                                {author.googleLink && <a className="google-plus" href="#">
                                    <i className="fab fa-google-plus-g" />
                                    <i className="fab fa-google-plus-g" />
                                </a>}
                                {author.vineLink && <a className="vine" href="#">
                                    <i className="fab fa-vine" />
                                    <i className="fab fa-vine" />
                                </a>}
                            </div>
                            {isProfile ? <Link className="btn btn-bordered btn-smaller" to={'/update-account'}>Update Profile</Link> : <></>}
                        </div>
                    </div>
                </div>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>

    );
}

export default AuthorProfile;
