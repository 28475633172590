import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Layout from "./layout";
import Home from "../themes/home";
import Authors from "../themes/authors";
import Auctions from "../themes/auctions";
import Collections from "../themes/collections";
import CollectionDetails from "../themes/collectionDetails";
import ListedItems from "../themes/listed-items";
import ItemDetails from "../themes/item-details";
import Activity from "../themes/activity";
import Blog from "../themes/blog";
import BlogSingle from "../themes/blog-single";
import HelpCenter from "../themes/help-center";
import Contact from "../themes/contact";
import Bridge from "../themes/bridge";

import Create from "../themes/create";
import Author from "../themes/author";
import UpdateProfile from "../themes/updateAccount";

function MyRouts(props) {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" exact render={() => (<Layout {...props}><Home {...props} /></Layout>)} />
          <Route path="/explore/authors" exact render={() => (<Layout {...props}><Authors {...props} /></Layout>)} />
          <Route path="/explore/live-auctions" exact render={() => (<Layout {...props}><Auctions {...props} /></Layout>)} />
          <Route path="/explore/listed-items" exact render={() => (<Layout {...props}><ListedItems {...props} /></Layout>)} />
          <Route path="/explore/collections" exact render={() => (<Layout {...props}><Collections {...props} /></Layout>)} />
          <Route path="/collections/:collection" exact render={() => (<Layout {...props}><CollectionDetails {...props} /></Layout>)} />
          <Route path="/item-details/:collection/:id" exact render={() => (<Layout {...props}><ItemDetails {...props} /></Layout>)} />
          <Route path="/activity" exact render={() => (<Layout {...props}><Activity {...props} /></Layout>)} />
          <Route path="/blog" exact render={() => (<Layout {...props}><Blog {...props} /></Layout>)} />
          <Route path="/blog/:id" exact render={() => (<Layout {...props}><BlogSingle {...props} /></Layout>)} />
          <Route path="/help-center" exact render={() => (<Layout {...props}><HelpCenter {...props} /></Layout>)} />
          <Route path="/contact" exact render={() => (<Layout {...props}><Contact {...props} /></Layout>)} />
          <Route path="/bridge" exact render={() => (<Layout {...props}><Bridge {...props} /></Layout>)} />

          <Route path="/create" exact render={() => (<Layout {...props}><Create {...props} /></Layout>)} />
          <Route path="/account/:address" exact render={() => (<Layout {...props}><Author {...props} /></Layout>)} />
          <Route path="/update-account" exact render={() => (<Layout {...props}><UpdateProfile {...props} /></Layout>)} />
        </Switch>
      </Router>
    </div>
  );
}

export default MyRouts;