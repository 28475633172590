export const InputGroupPrepend = {
    top: 0,
    left: 0,
    width: 110,
    height: '100%',
    backgroundColor: 'transparent',
}
export const coverStyle = {
    height: "280px",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "0 0",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
}