import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { IconButton, Snackbar, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios'
import Modal from "react-modal";
import * as S from "./styles";
import { useHistory } from "react-router-dom";
import { ipfsBaseURL, getIpfshash, getIpfsHashFromFile } from "../../utils/ipfs";
import { addItem, createNewCollection } from "../../utils/contracts";
import { IPFS_URL } from '../../utils/constants';
import DefaultNFTLogo from '../../assets/img/kaikas.png';
import { getContractInfo } from '../../utils';

const Create = (props) => {
    let history = useHistory();
    const { account, chainId, library } = useWeb3React();
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [mainFile, setMainFile] = useState(null)
    const [mainFileHash, setMainFileHash] = useState("");
    const [mainFileUploading, setMainFileUploading] = useState(false);
    const [showCoverUpload, setShowCoverUpload] = useState(false);
    const [coverImgFile, setCoverImgFile] = useState(null)
    const [coverImgHash, setCoverImgHash] = useState("");
    const [coverImageUploading, setCoverImageUploading] = useState(false);
    const [mediaType, setMediaType] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [amountItem, setAmountItem] = useState(1);
    const [royaltyItem, setRoyaltyItem] = useState(10);
    const [category, setCategory] = useState("");
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [collections, setCollections] = useState([]);
    const [categories, setCategories] = useState([]);
    const [properties, setProperties] = useState([{ trait_type: '', value: '' }]);
    const [creatingItem, setCreatingItem] = useState(false);

    const [showCreateCollectionDlg, setShowCreateCollectionDlg] = useState(false)
    const [collectionFile, setCollectionFile] = useState(null)
    const [collectionImgHash, setCollectionImgHash] = useState("");
    const [collectionImgUploading, setCollectionImgUploading] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState("");
    const [newCollectionSymbol, setNewCollectionSymbol] = useState("");
    const [newCollectionDescription, setNewCollectionDescription] = useState("");
    const [newCollectionRoyalty, setNewCollectionRoyalty] = useState("");
    const [creatingCollection, setCreatingCollection] = useState(false);

    const modalOverlayBgColor = 'rgba(60, 57, 56, 0.85)';
    const modalBgColor = 'rgba(222, 218, 221, 0.975)';
    const modalTextColor = 'black';
    const { address: defaultAddress } = getContractInfo('NationERC1155', chainId || null);

    const setPropertyItem = (type, value, index, action) => {
        let newProperties = [];
        if (action === 'remove') {
            for (let i = 0; i < properties.length; i++) {
                if (i === index) continue;
                newProperties.push(properties[i])
            }
        }
        else if (action === "add") {
            for (let k = 0; k < properties.length; k++) {
                newProperties.push(properties[k])
            }
            newProperties.push({ trait_type: '', value: '' });
        }
        else {
            for (let j = 0; j < properties.length; j++) {
                let item = properties[j];
                if (j === index) item[type] = value;
                newProperties.push(item)
            }
        }
        setProperties(newProperties);
    }
    function fetchCollections() {
        axios.get(`/collection?owner=${props.user.address}`)
            .then((res) => {
                setCollections(res.data.collections);
                res.data.collections.forEach((item, i) => {
                    if (item.address === defaultAddress.toLowerCase()) {
                        setSelectedCollection(item);
                    }
                });
            })
            .catch((err) => {
                console.log("err: ", err.message);
                setCollections([]);
            });
    }

    useEffect(() => {
        if (props.user && collections.length === 0) fetchCollections();
    }, [props.user, selectedCollection]);

    useEffect(() => {
        if (categories.length === 0) fetchCategories();
    }, [categories]);

    function fetchCategories() {
        axios.get(`/categories`)
            .then((res) => {
                if (res.data.categories.length > 1) {
                    setCategories(res.data.categories);
                    setCategory(res.data.categories[0]?.name || "");
                }
            })
            .catch((err) => {
                console.log("err: ", err.message);
                setCategories([]);
            });
    }
    function handleMainFile(event) {
        const fileType = event.target.files[0].type.split("/")[0];
        if (fileType === "image" || fileType === "video") {
            setMediaType(fileType);
            setCoverImgFile(null);
            setCoverImgHash("");
            setShowCoverUpload(false);
            setMainFile(event.target.files[0]);
            setMainFileUploading(true);
            getIpfsHashFromFile(event.target.files[0]).then((hash) => {
                setMainFileHash(`${ipfsBaseURL}${hash}`);
                setMainFileUploading(false)
            });
        } else if (fileType === "audio") {
            setMainFile(event.target.files[0]);
            setMainFileUploading(true);
            getIpfsHashFromFile(event.target.files[0]).then((hash) => {
                setMainFileHash(`${ipfsBaseURL}${hash}`);
                setMainFileUploading(false);
                setMediaType(fileType);
                setShowCoverUpload(true);
            });
        }
    }
    function closeMainFile() {
        setMainFile(null)
        setMainFileHash("")
        setMainFileUploading(false)
        setShowCoverUpload(false)

        setCoverImgFile(null)
        setCoverImgHash("")
        setCoverImageUploading(false)
        setMediaType("")
    }
    function handleCoverImg(event) {
        const fileType = event.target.files[0].type.split("/")[0];
        if (fileType === "image") {
            setCoverImgFile(event.target.files[0]);
            setCoverImageUploading(true);
            getIpfsHashFromFile(event.target.files[0]).then((hash) => {
                setCoverImgHash(`${ipfsBaseURL}${hash}`);
                setCoverImageUploading(false);
            });
        }
    }
    function closeCoverImg() {
        setCoverImgFile(null);
        setCoverImgHash("");
        setCoverImageUploading(false);
    }
    function handleCollectionImg(event) {
        const fileType = event.target.files[0].type.split("/")[0]
        if (fileType === "image") {
            setCollectionImgUploading(true);
            setCollectionFile(event.target.files[0]);
            getIpfsHashFromFile(event.target.files[0]).then((hash) => {
                setCollectionImgHash(`${ipfsBaseURL + hash}`)
                setCollectionImgUploading(false)
            });
        }
    }
    function closeCollectionImg() {
        setCollectionFile(null)
        setCollectionImgHash("")
        setCollectionImgUploading(false)
    }
    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };
    function onCreateCollection(e) {
        e.preventDefault();

        if (!newCollectionName) {
            setSnackBarMessage("Please Input Collection Name!")
            setOpenSnackbar(true)
            return
        }
        if (!newCollectionSymbol) {
            setSnackBarMessage("Please Input Collection Symbol!")
            setOpenSnackbar(true)
            return
        }
        if (!collectionImgHash) {
            setSnackBarMessage("Please Select Collection Image!")
            setOpenSnackbar(true)
            return
        }
        let royalty = parseFloat(newCollectionRoyalty);
        if (royalty) royalty = parseInt(royalty * 10);
        else royalty = 0;
        setCreatingCollection(false);
        // call createCollection contract function
        axios.get(`/collection/exist?name=${newCollectionName}`)
            .then(() => {
                setSnackBarMessage("Collection Name already exists!")
                setOpenSnackbar(true)
                return;
            })
            .catch((error) => {
                if (error.response && error.response.status !== 404) {
                    setSnackBarMessage("Unknown Error!")
                    setOpenSnackbar(true)
                    return;
                } else if(error && !error.response) {
                    console.error('Error with collection route: ', error)

                    setSnackBarMessage(error.response.data.message)
                    setOpenSnackbar(true)
                    return;
                }

                setCreatingCollection(true);
                getIpfshash({
                    name: newCollectionName,
                    description: newCollectionDescription,
                    image: collectionImgHash,
                    seller_fee_basis_points: newCollectionRoyalty,
                    fee_recipient: account
                }).then(async hash => {
                    console.log('IPFS hash: ', hash);
                    createNewCollection(
                        newCollectionName,
                        newCollectionSymbol,
                        ipfsBaseURL + hash,
                        royalty,
                        false, // bPublic by approved staff
                        chainId,
                        await library.getSigner(),
                        1, // TODO: Add erc1155
                    ).then(async (collectionAddress) => {
                        if (collectionAddress) {
                            await axios.get(`/sync_block`);
                            setNewCollectionName("");
                            setCollectionImgHash("");
                            setNewCollectionDescription("");
                            setNewCollectionSymbol("");
                            setNewCollectionRoyalty("");
                            setSnackBarMessage("Success!");
                            setOpenSnackbar(true);
                            setShowCreateCollectionDlg(false);
                            fetchCollections();
                            setCreatingCollection(false);
                        } else {
                            setCreatingCollection(false);
                            setSnackBarMessage("Transaction failed!");
                            setOpenSnackbar(true);
                        }
                    });
                }).catch(e => {
                    console.log("getIpfshash Error: ", e);
                })
            });
    }
    function onCreateItem(event) {
        event.preventDefault();
        if (amountItem === 0) {
            setSnackBarMessage("Please specify amount more than 0!")
            setOpenSnackbar(true)
            return
        }
        if (!name) {
            setSnackBarMessage("Please Input Title!")
            setOpenSnackbar(true)
            return
        }
        if (!description) {
            setSnackBarMessage("Please Input Description!")
            setOpenSnackbar(true)
            return
        }
        if (royaltyItem > 50 || royaltyItem < 5) {
            setSnackBarMessage("Please Input Royalty Correctly!")
            setOpenSnackbar(true)
            return
        }
        if (!category) {
            setSnackBarMessage("Please Select Category!")
            setOpenSnackbar(true)
            return
        }
        if (!selectedCollection) {
            setSnackBarMessage("Please Select Collection!")
            setOpenSnackbar(true)
            return
        }
        if (!mainFileHash) {
            setSnackBarMessage("Please Upload file!")
            setOpenSnackbar(true)
            return
        }
        if ((mediaType === "audio") && !coverImgHash) {
            setSnackBarMessage("Please Upload cover image!")
            setOpenSnackbar(true)
            return
        }
        let metadata = [];
        for (let i = 0; i < properties.length; i++) {
            if (!properties[i].trait_type || !properties[i].value) continue;
            metadata.push(properties[i])
        }
        const createData = {
            assetType: mediaType,
            category: category,
            name: name,
            description: description,
            image: mainFileHash,
            coverImage: coverImgHash,
            attributes: metadata,
        };
        setCreatingItem(true);
        getIpfshash(createData).then(async hash => {
            let tokenUri = IPFS_URL + hash;
            addItem(
                selectedCollection.address,
                tokenUri,
                amountItem,
                royaltyItem * 10,
                chainId,
                await library.getSigner(),
                selectedCollection.type,
            ).then((tokenId) => {
                if (tokenId) {
                    axios.get(`/sync_block`)
                        .then((res) => {
                            setSnackBarMessage("Success");
                            setOpenSnackbar(true);
                            setCreatingItem(false);
                            history.push(`/account/${account}`);
                            return true;
                        })
                        .catch((error) => {
                            if (error.response) {
                                setSnackBarMessage(error.response.data.message);
                                setOpenSnackbar(true);
                                setCreatingItem(false);
                            }
                        });
                } else {
                    setSnackBarMessage("Failed Transaction");
                    setCreatingItem(false);
                    setOpenSnackbar(true);
                }
            });
        });
    }

    function setAmountItemValidator(value) {
        value = value.replace(/^0/, "").replace(/\D/g, '');
        if (parseInt(value) > 1_000_000_000) return;
        setAmountItem(value);
    }

    function setRoyaltyItemValidator(value) {
        value = value.replace(/^0/, "").replace(/\D/g, '');
        if (parseInt(value) > 50) return;
        setRoyaltyItem(value);
    }

    function setRoyaltyCollectionValidator(value) {
        value = value.replace(/^0/, "").replace(/\D/g, '');
        if (parseInt(value) > 50) return;
        setNewCollectionRoyalty(value);
    }

    return (
        <section className="author-area">
            <div className="container">
                <div className="item-form card no-hover">
                    <form onSubmit={e => onCreateItem(e)}>
                        <div className="row">
                            {/* Upload media item */}
                            <div className='col-12'>
                                <S.UploadField>
                                    <S.label>Upload file</S.label>
                                    <S.UploadContainer style={{ display: mainFile ? "none" : "" }}>
                                        <S.UploadCaption>Image/Audio/Video Files(Max 200mb)</S.UploadCaption>
                                        <S.ChooseFileBtn>
                                            Choose File
                                            <S.FileInput type="file" value="" accept="audio/*,video/*,image/*" onChange={handleMainFile} />
                                        </S.ChooseFileBtn>
                                    </S.UploadContainer>
                                    <S.PreviewContainer style={{ display: mainFile ? "" : "none" }}>
                                        <S.CloseIconContainer style={{ display: mainFileHash ? "" : "none" }}>
                                            <CloseIcon onClick={() => closeMainFile()} fontSize="small" />
                                        </S.CloseIconContainer>
                                        <S.MediaContainer>
                                            <CircularProgress style={{ display: mainFileUploading ? "" : "none", width: "30px", height: "30px", color: "red" }} />
                                            <S.ImagePreview style={{ display: mainFileHash && mediaType === "image" ? "" : "none" }} src={mainFileHash} />
                                            <S.AudioPreview style={{ display: mainFileHash && mediaType === "audio" ? "" : "none" }} src={mainFileHash} autoPlay loop controls />
                                            <S.VideoPreview style={{ display: mainFileHash && mediaType === "video" ? "" : "none" }} src={mainFileHash} autoPlay loop controls />
                                        </S.MediaContainer>
                                    </S.PreviewContainer>
                                </S.UploadField>
                                <S.UploadField style={{ display: showCoverUpload ? "" : "none" }}>
                                    <S.label>Upload cover</S.label>
                                    <S.UploadContainer style={{ display: coverImgFile ? "none" : "" }}>
                                        <S.UploadCaption>JPG, PNG, GIF, WEBP. Max 100mb</S.UploadCaption>
                                        <S.ChooseFileBtn>
                                            Choose File
                                            <S.FileInput type="file" value="" accept="image/*" onChange={handleCoverImg} />
                                        </S.ChooseFileBtn>
                                    </S.UploadContainer>
                                    <S.PreviewContainer style={{ display: coverImgFile ? "" : "none" }}>
                                        <S.CloseIconContainer style={{ display: coverImgHash ? "" : "none" }}>
                                            <CloseIcon onClick={() => closeCoverImg()} fontSize="small" />
                                        </S.CloseIconContainer>
                                        <S.MediaContainer>
                                            <CircularProgress style={{ display: coverImageUploading ? "" : "none", width: "30px", height: "30px", color: "red" }} />
                                            <S.ImagePreview style={{ display: coverImgHash ? "" : "none" }} src={coverImgHash} />
                                        </S.MediaContainer>
                                    </S.PreviewContainer>
                                    <S.Option>Please add cover Image to your media file</S.Option>
                                </S.UploadField>
                            </div>
                            {/* collection */}
                            <div className='col-12'>
                                <S.SelectCollection>
                                    <S.label>Choose collection</S.label>
                                    <S.Collections>
                                        <S.collection onClick={() => setShowCreateCollectionDlg(true)}>
                                            <S.CollectionPlusIcon size={48} />
                                            <S.CollectionName>Create</S.CollectionName>
                                            <S.CollectionType>ERC-721</S.CollectionType>
                                        </S.collection>
                                        {
                                            collections.map((collection, index) => {
                                                return (
                                                    <S.collection key={index} onClick={() => setSelectedCollection(collection)} className={selectedCollection === collection ? 'active' : ''}>
                                                        <S.CollectionIcon src={collection.image} onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = DefaultNFTLogo;
                                                        }} />
                                                        <S.CollectionName>{collection.name}</S.CollectionName>
                                                        <div style={{ fontSize: 12 }}>Royalty: {collection.royalty / 10 || 0} %</div>
                                                        <S.CollectionType>{(collection.type === 1) ? 'ERC-721' : 'ERC-1155'}</S.CollectionType>
                                                    </S.collection>
                                                );
                                            })
                                        }
                                    </S.Collections>
                                </S.SelectCollection>
                            </div>
                            {/* Form */}

                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <input type="text" className="form-control" placeholder="Title" value={name} onChange={event => setName(event.target.value)} required />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <textarea className="form-control" placeholder="Text" cols={30} rows={3} defaultValue={description}
                                        onChange={event => setDescription(event.target.value)} required />
                                </div>
                            </div>
                            {selectedCollection?.type === 2 && (
                                <div className="col-12">
                                    <div className="form-group">
                                        <S.label>Amount</S.label>
                                        <S.Input className="form-control" type={"number"} value={amountItem} onChange={event => setAmountItemValidator(event.target.value)} required />
                                    </div>
                                </div>
                            )}
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <S.label>Royalty</S.label>
                                    <S.Input className="form-control" type={"number"} value={royaltyItem} onChange={event => setRoyaltyItemValidator(event.target.value)} required />
                                    <S.Option>Suggested: 10%, Minimum is 5%, Maximum is 50%</S.Option>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <S.label>Select Category</S.label>
                                    <select className="form-control" defaultValue={category} onChange={event => setCategory(event.target.value)}
                                        style={{ height: 50, padding: "0 10" }} required>
                                        {
                                            categories.map((categoryItem, index) => {
                                                return (
                                                    <option key={index} value={categoryItem.name}>{categoryItem.name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <S.label>Properties(Optional)</S.label>
                                    {properties.map((property, index) => (<div className="row" key={index}>
                                        <div className='col-11' style={{ paddingLeft: 0 }}>
                                            <div className='row'>
                                                <div className="col-6" style={{ paddingLeft: 0 }}>
                                                    <div className="form-group">
                                                        <input className="form-control" value={property.trait_type} placeholder="e.g. Size"
                                                            onChange={event => setPropertyItem('trait_type', event.target.value, index, '')} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <input className="form-control" value={property.value} placeholder="e.g. M"
                                                            onChange={event => setPropertyItem('value', event.target.value, index, '')} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-1'>
                                            <S.PropertyAction>
                                                {(index < properties.length - 1) ? <S.PropertyActionWrap onClick={() => setPropertyItem('', '', index, 'remove')}>
                                                    <span style={{ fontSize: 32 }}>-</span>
                                                </S.PropertyActionWrap> : <S.PropertyActionWrap onClick={() => setPropertyItem('', '', index, 'add')}>
                                                    <span style={{ fontSize: 32 }}>+</span>
                                                </S.PropertyActionWrap>}
                                            </S.PropertyAction>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-lg w-100 btn-solid-green mt-3 mt-sm-4" type='submit'>
                                    {
                                        creatingItem ? <CircularProgress style={{ width: "16px", height: "16px", color: "white", }} /> : "Create item"
                                    }
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            {/* Create Collection Modal */}
            <Modal
                isOpen={showCreateCollectionDlg}
                onRequestClose={() => setShowCreateCollectionDlg(false)}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: modalOverlayBgColor,
                        zIndex: 99,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '500px',
                        borderRadius: '20px',
                        backgroundColor: modalBgColor,
                        zIndex: 999
                    },
                }}
            >
                <S.ModalBody>
                    <form onSubmit={(e) => onCreateCollection(e)}>
                        <S.ModalTitle>Collection</S.ModalTitle>
                        <S.ModalContent>
                            <S.UploadContainer style={{ display: collectionFile ? "none" : "" }}>
                                <S.UploadCaption>We recommend an image of at least 400X400.</S.UploadCaption>
                                <S.ChooseFileBtn>
                                    Choose File
                                    <S.FileInput type="file" value="" accept="image/*" onChange={handleCollectionImg} />
                                </S.ChooseFileBtn>
                            </S.UploadContainer>

                            <S.PreviewContainer style={{ display: collectionFile ? "" : "none" }}>
                                <S.CloseIconContainer style={{ display: collectionImgHash ? "" : "none" }}>
                                    <CloseIcon onClick={() => closeCollectionImg()} fontSize="small" />
                                </S.CloseIconContainer>
                                <S.MediaContainer>
                                    <CircularProgress style={{ display: collectionImgUploading ? "" : "none", width: "30px", height: "30px", color: "red" }} />
                                    <S.ImagePreview style={{ display: collectionImgHash ? "" : "none" }} src={collectionImgHash} />
                                </S.MediaContainer>
                            </S.PreviewContainer>
                        </S.ModalContent>
                        <S.Field>
                            <S.Input placeholder={"Enter name*"} value={newCollectionName} onChange={event => setNewCollectionName(event.target.value)} required />
                        </S.Field>
                        <S.Field>
                            <S.Input placeholder={"Enter symbol*"} value={newCollectionSymbol} onChange={event => setNewCollectionSymbol(event.target.value)} required />
                        </S.Field>
                        <S.Field>
                            <S.Input placeholder={"Enter description"} value={newCollectionDescription} onChange={event => setNewCollectionDescription(event.target.value)} />
                        </S.Field>
                        <S.Field>
                            <S.Input type={'number'} placeholder={"Enter royalty, maximum is 50%."} value={newCollectionRoyalty} onChange={event => setRoyaltyCollectionValidator(event.target.value)} />
                        </S.Field>
                        <S.ModalAction>
                            {
                                creatingCollection ? <button className="btn btn-bordered w-100 mt-sm-4" type='button' disabled>
                                    <CircularProgress style={{ width: "16px", height: "16px", color: "white", }} />
                                </button> : <button className="btn btn-solid-green w-100 mt-sm-4" type='submit'>
                                    Create collection
                                </button>
                            }

                        </S.ModalAction>
                    </form>
                </S.ModalBody>
            </Modal>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseDialog}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseDialog}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </section>
    );
}

export default Create;
