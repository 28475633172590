import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NFT from "../Item/NFT";

const ExploreListedItems = () => {
    const [items, setItems] = useState([]);
    const [noItems, setNoItems] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialItemsLoaded, setInitialItemsLoaded] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (initialItemsLoaded) setPage(1);
        setLoading(true);
        axios.get(`/item?saleType=fixed&page=${page}`)
            .then(res => {
                if (!res.data.items.length) {
                    setNoItems(true);
                    return;
                }
                if (page === 1) setItems(res.data.items);
                else setItems(items.concat(res.data.items));
                setLoading(false);
            }).catch(e => {
                console.log(e);
                setLoading(false);
                setItems([...items]);
                setNoItems(true);
            })
    }, [page]);

    function loadMore() {
        if (!loading) {
            setInitialItemsLoaded(false);
            setPage(page + 1);
        }
    }
    return (
        <section className="explore-area load-more">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        <div className="intro text-center">
                            <span>Explore Listed NFTs</span>
                            <h3 className="mt-3 mb-0">Exclusive Digital Assets</h3>
                        </div>
                    </div>
                </div>
                <div className="row items">
                    {items.map((item, idx) => {
                        return (
                            <div key={`exf_${idx}`} className="col-12 col-sm-6 col-md-4 col-lg-3 item">
                                <NFT item={item} />
                            </div>
                        );
                    })}
                </div>
                <div className="load-more" style={{ display: noItems ? "none" : "", textAlign: 'center' }}>
                    <button onClick={() => loadMore()} className="btn mt-3 mt-sm-4">
                        {loading ? "Loading..." : "Load more"}
                    </button>
                </div>
            </div>
        </section>
    );
}

export default ExploreListedItems;
