import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import approvedBadge from '../../assets/img/approvedBadge.svg';
import { APP_COIN } from '../../utils/constants';

const Activity = () => {
    const filterItems = [
        { title: 'Minting' },
        { title: 'Listing' },
        { title: 'Delisting' },
        { title: 'Auction' },
        { title: 'Bids' },
        { title: 'Purchase' },
    ];
    const [filter, setFilter] = useState('recent');
    const [allEvents, setAllEvents] = useState([]);
    const [bidEvents, setBidEvents] = useState([]);
    const [purchaseEvents, setPurchaseEvents] = useState([]);
    useEffect(() => {
        axios.get(`/activities?filter=${filter}&page=1`)
            .then(res => {
                if (filter === 'Bids') setBidEvents(res.data.events);
                else if (filter === 'Purchase') setPurchaseEvents(res.data.events);
                else setAllEvents(res.data.events);
            }).catch(e => {
                console.log(e);
            })
    }, [filter]);

    const changeFilter = (filterKey) => {
        setFilter(filterKey);
    };
    const dateFormat = (timestapm) => {
        let dateString = new Date(timestapm * 1000).toISOString().slice(0, 10) + " " + new Date(timestapm * 1000).toISOString().slice(11, 19);
        return dateString;
    }
    return (
        <section className="activity-area load-more">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro mb-4">
                            <div className="intro-content">
                                <span>CREATIVE</span>
                                <h3 className="mt-3 mb-0">Activities</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row items">
                    <div className="col-12 col-md-6 col-lg-8">
                        <ul className="netstorm-tab nav nav-tabs" id="nav-tab">
                            <li>
                                <a className={filter !== 'Bids' && filter !== 'Purchase' ? 'active' : undefined} id="nav-home-tab"
                                    data-toggle="pill" href="#nav-home" onClick={() => changeFilter('Recent')}>
                                    <h5 className="m-0">Recent</h5>
                                </a>
                            </li>
                            <li>
                                <a className={filter === 'Bids' ? 'active' : undefined} id="nav-profile-tab"
                                    data-toggle="pill" href="#nav-profile" onClick={() => changeFilter('Bids')}>
                                    <h5 className="m-0">Bids</h5>
                                </a>
                            </li>
                            <li>
                                <a className={filter === 'Purchase' ? 'active' : undefined} id="nav-contact-tab"
                                    data-toggle="pill" href="#nav-contact" onClick={() => changeFilter('Purchase')}>
                                    <h5 className="m-0">Purchase</h5>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="nav-tabContent">
                            <div className={filter !== 'Bids' && filter !== 'Purchase' ? 'tab-pane fade show active' : 'tab-pane fade'} id="nav-home">
                                <ul className="list-unstyled">
                                    {allEvents.map((item, idx) => (
                                        <li key={`ato_${idx}`} className="single-tab-list d-flex align-items-center">
                                            <Link to={`/account/${item.name === 'Minted' || item.name === 'MarketDelisted' || item.name === 'AuctionDelisted' ? item.to : item.from}`} >
                                                <img className="avatar-lg" src={item.name === 'Minted' || item.name === 'MarketDelisted' || item.name === 'AuctionDelisted' ? item.toUser.profilePic : item.fromUser.profilePic} alt="" />
                                                {(item.toUser.isApproved && (
                                                    <img className="activity-verification" src={approvedBadge} alt="approved" />
                                                ))}
                                            </Link>
                                            <div className="activity-content ml-4">
                                                <Link to={`/item-details/${item.itemCollection}/${item.tokenId}`}>
                                                    <h5 className="mt-0 mb-2">{item.item.name}</h5>
                                                </Link>
                                                <p className="m-0">{item.name === 'AuctionCreated' ? 'Auction created' : (item.name === 'AuctionCanceled' ? 'Auction canceled' : item.name)}
                                                    &nbsp;for <strong>{item.price}&nbsp;{APP_COIN}</strong>
                                                    &nbsp; at {dateFormat(item.timestamp)} <br />by &nbsp;
                                                    <Link to={`/account/${item.name === 'Minted' || item.name === 'MarketDelisted' || item.name === 'AuctionDelisted' ? item.to : item.from}`} style={{ display: 'inline-block' }}>
                                                        @{item.name === 'Minted' || item.name === 'MarketDelisted' || item.name === 'AuctionDelisted' ? item.toUser.name : item.fromUser.name}</Link></p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={filter === 'Bids' ? 'tab-pane fade show active' : 'tab-pane fade'} id="nav-profile">
                                <ul className="list-unstyled">
                                    {bidEvents.map((item, idx) => (
                                        <li key={`att_${idx}`} className="single-tab-list d-flex align-items-center">
                                            <Link to={`/account/${item.from}`} >
                                                <img className="avatar-lg" src={item.fromUser.profilePic} alt="" />
                                                {(item.toUser.isApproved && (
                                                    <img className="activity-verification" src={approvedBadge} alt="approved" />
                                                ))}
                                            </Link>
                                            <div className="activity-content ml-4">
                                                <Link to={`/item-details/${item.itemCollection}/${item.tokenId}`}>
                                                    <h5 className="mt-0 mb-2">{item.item.name}</h5>
                                                </Link>
                                                <p className="m-0">Bid listed for <strong>{item.bidPrice}&nbsp;{APP_COIN}</strong>
                                                    &nbsp; at {dateFormat(item.timestamp)} <br />by &nbsp;
                                                    <Link to={`/account/${item.from}`} style={{ display: 'inline-block' }}>@{item.fromUser.name}</Link></p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={filter === 'Purchase' ? 'tab-pane fade show active' : 'tab-pane fade'} id="nav-contact">
                                <ul className="list-unstyled">
                                    {purchaseEvents.map((item, idx) => (
                                        <li key={`atth_${idx}`} className="single-tab-list d-flex align-items-center">
                                            <Link to={`/account/${item.from}`} >
                                                <img className="avatar-lg" src={item.fromUser.profilePic} alt="" />
                                                {(item.toUser.isApproved && (
                                                    <img className="activity-verification" src={approvedBadge} alt="approved" />
                                                ))}
                                            </Link>
                                            <div className="activity-content ml-4">
                                                <Link to={`/item-details/${item.itemCollection}/${item.tokenId}`}>
                                                    <h5 className="mt-0 mb-2">{item.item.name}</h5>
                                                </Link>
                                                <p className="m-0">Purchased for <strong>{item.price} &nbsp; {APP_COIN}</strong>
                                                    &nbsp; at {dateFormat(item.timestamp)} <br />by &nbsp;
                                                    <Link to={`/account/${item.to}`} style={{ display: 'inline-block' }}>@{item.toUser.name}</Link></p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="activity-content mt-5 mt-lg-0">
                            <div className="single-widget">
                                <div className="widget filter-widget">
                                    <h4 className="title">Filters</h4>
                                    <div className="widget-content">
                                        <div className="widget-content filter-widget-items mt-3">
                                            {filterItems.map((item, idx) => (
                                                <div key={`fd_${idx}`} className={`badge tag ${item.title === filter ? 'active' : ''}`}
                                                    style={{ cursor: 'pointer' }} onClick={() => changeFilter(item.title)}>{item.title}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Activity;
