import React from 'react';

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Author from '../components/Authors/Authors';
import TopSeller from '../components/TopSeller/TopSellerTwo';

const Authors = (props) => {
    return (
        <div>
            <Breadcrumb title="Authors" subpage="Pages" page="Authors" />
            <TopSeller />
            <Author />
        </div>
    );
}

export default Authors;