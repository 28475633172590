import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import NFT from "../components/Item/NFT";

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

const CollectionDetails = (props) => {
    const { collection } = useParams();
    const [items, setItems] = useState([]);
    const [noItems, setNoItems] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialItemsLoaded, setInitialItemsLoaded] = useState(true);
    const [page, setPage] = useState(1);
    useEffect(() => {
        if (initialItemsLoaded) setPage(1);
        setLoading(true);
        axios.get(`/item?itemCollection=${collection}&page=${page}`)
            .then(res => {
                if (!res.data.items.length) {
                    setNoItems(true);
                    return;
                }
                if (page === 1) setItems(res.data.items);
                else setItems(items.concat(res.data.items));
                setLoading(false);
            }).catch(e => {
                console.log(e);
                setLoading(false);
                setItems([...items])
                setNoItems(true)
            })
    }, [page]);

    function loadMore() {
        if (!loading) {
            setInitialItemsLoaded(false);
            setPage(page + 1);
        }
    }
    return (
        <div>
            <Breadcrumb title="Collection Items" subpage="Explore" page="Explore Style 3" />
            <section className="author-area explore-area popular-collections-area">
                <div className="container">
                    <div className="row items explore-items" id='account_owned'>
                        {
                            items.map((item, index) => (
                                <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 item explore-item" key={index}>
                                    <NFT item={item} />
                                </div>
                            ))
                        }
                    </div>
                    <div className="load-more" style={{ display: noItems ? "none" : "", textAlign: 'center' }}>
                        <button onClick={() => loadMore()} className="btn mt-3 mt-sm-4">
                            {loading ? "Loading..." : "Load more"}
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CollectionDetails;
