import React from 'react';

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import useScript from '../hooks/useScript';

const Bridge = () => {

    useScript('https://widget.xp.network/wscript.js');

    return (
        <div className="main">
            <Breadcrumb title="Bridge" subpage="Explore" page="Bridge" />

            <section classNme="">
                <div className="container">
                    
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 col-lg-7">
                            <div class="intro text-center">
                                <span>Bridge</span>
                                <h3 class="mt-3 mb-0">Transfer NFTs</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="bridge-container">
                                <iframe src="https://widget.xp.network?wid=63c0230956e0b15793000abb" frameborder="0" width="100%" height="100%" id="xpnetWidget"></iframe>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
}

export default Bridge;