import React from 'react';

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import LiveAuctions from '../components/Auctions/LiveAuctions';

const Auctions = (props) => {
    return (
        <div className="main">
            <Breadcrumb title="Auctions" subpage="Explore" page="Live Auctions" />
            <LiveAuctions {...props} />
        </div>
    );
}

export default Auctions;