import { Contract } from 'ethers';
import NationFactoryABI from '../contracts/NationFactory.json';
import NationERC721ABI from '../contracts/NationERC721.json';
import NationERC1155ABI from '../contracts/NationERC1155.json';
import NationMarketABI from '../contracts/NationMarket.json';
import NationAuctionABI from '../contracts/NationAuction.json';
import NationProxyAbi from '../contracts/NationProxyAbi.json';
import { NETWORK_ID } from './constants';

export const CONTRACTS_BY_NETWORK = {
  // Godwoken Testnet v1
  "71401": {
    NationFactory: {
      address: "0xb227849f7D3352E50A7892406C6e85c8Cf3A80aA",
      abi: NationFactoryABI,
    },
    NationERC721: {
      address: "0x0000000000000000000000000000000000000000",
      abi: NationERC721ABI,
    },
    NationERC1155: {
      address: "0x2a98D0f74f9188458819C57d7FF008325C582ff9",
      abi: NationERC1155ABI,
    },
    NationMarket: {
      address: "0x40e4355601b26eee458eb27c0758a443adcc53bd",
      abi: NationMarketABI
    },
    NationAuction: {
      address: "0xAc1c84D470eF33eeF32DF7B2954Fa4a17F8BCb11",
      abi: NationAuctionABI
    },
    NationProxy: {
      address: '0x759434bfb5cb3ed864AAfFD1F02C8fd5C5cfDe4d',
      abi: NationProxyAbi
    }
  },
   // Godwoken Mainnet v1
  "71402": {
    NationFactory: {
      address: "0xf08a422da1b83a78263180f83573b08b6e24b93e",
      abi: NationFactoryABI,
    },
    NationERC721: {
      address: "0x0000000000000000000000000000000000000000",
      abi: NationERC721ABI,
    },
    NationERC1155: {
      address: "0x40ccA4eb64757e7Ce80600A50EB08C0eC4d96ed3",
      abi: NationERC1155ABI,
    },
    NationMarket: {
      address: "0xa7e568afd214f7855dd321ecbef5d8e482a87420",
      abi: NationMarketABI
    },
    NationAuction: {
      address: "0xB4C41AB2F7620AC43B8d9030BD512fAB29aA82C6",
      abi: NationAuctionABI
    },
    NationProxy: {
      address: '0xfDAeEb2B3712DAc1fa331CFB0929113431967de3',
      abi: NationProxyAbi
    }
  }
}
export function getContractInfo(name, chainId) {
  if (!chainId) chainId = NETWORK_ID.toString();

  const contracts = CONTRACTS_BY_NETWORK?.[chainId];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}
export function getContractObj(name, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(info.address, info.abi, provider);
}
export function getCollectionContract(address, chainId, provider, type) {
  const info = getContractInfo(`Nation${(type === 2) ? 'ERC1155' : 'ERC721'}`, chainId);
  return !!info && new Contract(address, info.abi, provider);
}
export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 6) + '...' + str.slice(-4) : str
export function formatNum(value) {
  let intValue = Math.floor(value)
  if (intValue < 10) {
    return '' + parseFloat(value).toFixed(2)
  } else if (intValue < 1000) {
    return '' + intValue
  } else if (intValue < 1000000) {
    return parseFloat(intValue / 1000).toFixed(1) + 'K'
  } else if (intValue < 1000000000) {
    return parseFloat(intValue / 1000000).toFixed(1) + 'M'
  } else {
    return parseFloat(intValue / 1000000000).toFixed(1) + 'G'
  }
}
