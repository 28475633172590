import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import approvedBadge from '../../assets/img/approvedBadge.svg';
import DefaultNFTLogo from '../../assets/img/kaikas.png';
import { APP_COIN } from '../../utils/constants';

const TopSellerTow = () => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios.get(`/sellers?page=1&pageLimit=6`)
            .then(res => {
                setItems(res.data.sellers);
            }).catch(e => {
                console.log(e);
                setItems([]);
            })
    }, []);
    return (
        <section className="top-seller-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro m-0">
                            <div className="intro-content">
                                <span>CREATIVE ARIST</span>
                                <h3 className="mt-3 mb-0">Top Sellers</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row items">
                    {items.filter((item) => item.seller !== null).map((item, idx) => {
                        return (
                            <div key={`ts_${idx}`} className="col-12 col-sm-6 col-lg-4 item">
                                {/* Single Seller */}
                                <div className="card no-hover">
                                    <div className="single-seller d-flex align-items-center">
                                        <Link to={`/account/${item.seller.address}`}>
                                            <img className="avatar-md rounded-circle" src={item.seller.profilePic} alt=""
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = DefaultNFTLogo;
                                                }}
                                            />
                                            {(item?.seller.isApproved && (
                                                <img className="seller-owner-verification" src={approvedBadge} alt="approved" />
                                            ))}
                                        </Link>
                                        {/* Seller Info */}
                                        <div className="seller-info ml-3">
                                            <Link className="seller mb-2" to={`/account/${item.seller.address}`}>@{item.seller.name}</Link>
                                            <span>{item.totalAmount}&nbsp;{APP_COIN}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );

}

export default TopSellerTow;
