import React from 'react';

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ExploreListedItems from '../components/Explore/ExploreListedItems';

const ListedItems = (props) => {
    return (
        <div>
            <Breadcrumb title="Explore" subpage="Explore" page="Explore Style 3" />
            <ExploreListedItems />
        </div>
    );
}

export default ListedItems;