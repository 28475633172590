import React, { useState } from "react";

// hearder, footer, modalsearch, modalmenu, scrollup
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

function Layout(props) {
  const [isLogin, setIsLogin] = useState(false);
  return (
    <div className="main">
      <Header {...props} onLogin={() => setIsLogin(true)} onLogout={() => setIsLogin(false)} isLogin={isLogin} />
      {props.children}
      <Footer />
      <ModalSearch />
      <ModalMenu />
      <Scrollup />
    </div>
  );
}

export default Layout;