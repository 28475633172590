import React from 'react';
import AuthorProfile from '../components/Author/Author';

const Author = (props) => {

    return (
        <div>
            <AuthorProfile {...props} />
        </div>
    );
}

export default Author;