import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core'
import { ipfsBaseURL, getIpfsHashFromFile } from "../utils/ipfs";
import axios from 'axios'
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EasyCrop from '../components/EasyCrop';
import * as custom from '../custom/themeStyle';

const UpdateProfile = (props) => {
    const { user, login } = props;
    const [userProfile, setUserProfile] = useState(undefined)
    const { account, library } = useWeb3React();
    const [updating, setUpdating] = useState(false)
    const [snackBarMessage, setSnackBarMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [newName, setNewName] = useState("");
    const [newBio, setNewBio] = useState("");
    const [newFacebook, setNewFacebook] = useState("");
    const [newTwitter, setNewTwitter] = useState("");
    const [newGoogle, setNewGoogle] = useState("");
    const [newVine, setNewVine] = useState("");
    const [newProfileCoverSrc, setNewProfileCoverSrc] = useState("")
    const [newProfileCoverPosition, setNewProfileCoverPosition] = useState("")
    const [newProfilePicSrc, setNewProfilePicSrc] = useState("")
    const [coverImgUploading, setCoverImgUploading] = useState(false)
    const [profileImgUploading, setProfileImgUploading] = useState(false)

    const [pcimage, setPCImage] = useState(null);

    const handleImageUpload = async (e) => {
        setPCImage(URL.createObjectURL(e.target.files[0]));
      };

    useEffect(() => {
        console.log('user: ', user)
        if (!!user) {
            login();
        }
    }, [user, account, library]);
    useEffect(() => {
        if (account && !userProfile) {
            getUser()
        }
    }, [user]);
    function getUser() {
        axios.get(`/user/${account}`)
            .then(res => {
                setUserProfile(res.data.user);
                setNewProfilePicSrc(res.data.user.profilePic);
                setNewProfileCoverSrc(res.data.user.profileCover);
                setNewProfileCoverPosition(res.data.user.profileCoverPosition);
                setNewName(res.data.user.name);
                setNewBio(res.data.user.bio);
                setNewFacebook(res.data.user.facebookLink);
                setNewTwitter(res.data.user.twitterLink);
                setNewGoogle(res.data.user.googleLink);
                setNewVine(res.data.user.vineLink);
            })
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };
    function updateProfile() {
        setUpdating(true);
        let data = {
            address: account,
            name: newName || "NoName",
            bio: newBio || "",
            profilePic: newProfilePicSrc,
            profileCover: newProfileCoverSrc,
            profileCoverPosition: newProfileCoverPosition,
            facebookLink: newFacebook,
            twitterLink: newTwitter,
            googleLink: newGoogle,
            vineLink: newVine
        };

        axios.post("/user/update", data)
            .then(res => {
                setUpdating(false);
                setSnackBarMessage("Success");
                setOpenSnackbar(true);
            })
            .catch(err => {
                setUpdating(false)
                setSnackBarMessage(err.response.data.message)
                setOpenSnackbar(true)
            })
    }
    function handleProfileFile(event) {
        const fileType = event.target.files[0].type.split("/")[0]
        if (fileType === "image") {
            setProfileImgUploading(true)
            getIpfsHashFromFile(event.target.files[0]).then((hash) => {
                setNewProfilePicSrc(`${ipfsBaseURL}${hash}`);
                setProfileImgUploading(false)
            });
        }
    }
    function handleCoverFile(event) {
        const fileType = event.target.files[0].type.split("/")[0]
        if (fileType === "image") {
            setCoverImgUploading(true)
            getIpfsHashFromFile(event.target.files[0]).then((hash) => {
                setNewProfileCoverSrc(`${ipfsBaseURL}${hash}`);
                setCoverImgUploading(false)
            });
        }
    }


    const imgState = {
        image: newProfileCoverSrc,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 3 / 1,
        croppedAreaPixels: {},
    };

    const drawImage = () => { };

    const onCropChange = (crop) => {
        imgState.crop = crop;
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log('croppedArea: ', croppedArea);
        console.log('croppedAreaPixels: ', croppedAreaPixels);
        imgState.croppedAreaPixels = croppedAreaPixels;
    };

    const onZoomChange = (zoom) => {
        imgState.zoom = zoom;
    };



    return (
        <>
            {account &&
                <section className="author-area explore-area popular-collections-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12">
                                <div className="card no-hover text-center">
                                    <div className="image-over">
                                        <div className="card-img-top" id="user-profile-cover"
                                            style={{ backgroundImage: `url(${newProfileCoverSrc})`, ...custom.coverStyle }}
                                        >

                                            {/* <EasyCrop image={newProfileCoverSrc} /> */}

                                        </div>

                                        <div className="author">
                                            <div className="author-thumb avatar-lg">
                                                <img className="rounded-circle" src={`${newProfilePicSrc}`} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Card Caption */}
                                    <div className="card-caption col-12 p-0">
                                        {/* Card Body */}
                                        <div className="card-body item-form">
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <div className="inputField">
                                                        <input type="file" id='cover_input_file' accept="image/*" multiple={false} onChange={handleCoverFile} style={{ display: 'none' }} />
                                                        {coverImgUploading ?
                                                            <button className="btn btn-bordered mt-3 mt-sm-4">Uploading ...</button>
                                                            :
                                                            <button className="btn btn-bordered mt-3 mt-sm-4" onClick={() => document.getElementById('cover_input_file').click()}>Upload Cover Image</button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-6'>
                                                    <div className="inputField">
                                                        <input type="file" id='profile_input_file' accept="image/*" multiple={false} onChange={handleProfileFile} style={{ display: 'none' }} />
                                                        {profileImgUploading ?
                                                            <button className="btn btn-bordered mt-3 mt-sm-4">Uploading ...</button>
                                                            :
                                                            <button className="btn btn-bordered mt-3 mt-sm-4" onClick={() => document.getElementById('profile_input_file').click()}>Upload Profile Image</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col-12 col-md-6 input-group'>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend" style={custom.InputGroupPrepend}>
                                                            <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>Name: </span>
                                                        </div>
                                                        <input type="text" className="form-control" value={newName || ""} onChange={e => setNewName(e.target.value)} />
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend" style={custom.InputGroupPrepend}>
                                                            <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>Facebook: </span>
                                                        </div>
                                                        <input type="text" className="form-control" value={newFacebook || ""} onChange={e => setNewFacebook(e.target.value)} />
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend" style={custom.InputGroupPrepend}>
                                                            <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>Twitter: </span>
                                                        </div>
                                                        <input type="text" className="form-control" value={newTwitter || ""} onChange={e => setNewTwitter(e.target.value)} />
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend" style={custom.InputGroupPrepend}>
                                                            <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>Google: </span>
                                                        </div>
                                                        <input type="text" className="form-control" value={newGoogle || ""} onChange={e => setNewGoogle(e.target.value)} />
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend" style={custom.InputGroupPrepend}>
                                                            <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>Vine: </span>
                                                        </div>
                                                        <input type="text" className="form-control" value={newVine || ""} onChange={e => setNewVine(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-6'>
                                                    <label>Bio</label>
                                                    <div className="form-group mt-3">
                                                        <textarea className="form-control" rows={7} value={newBio || ""} onChange={e => setNewBio(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <p className="my-3">{`${user.bio ? user.bio : ''}`}</p>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" value={account || ''} readOnly style={{ textAlign: 'center' }} />
                                                <div className="input-group-append">
                                                    <button><i className="icon-docs" /></button>
                                                </div>
                                            </div>

                                            {updating ?
                                                <div className="btn btn-solid-green">Updating ...</div>
                                                :
                                                <div className="btn btn-solid-green" onClick={() => updateProfile()}>Submit Updates</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>

    );
}

export default UpdateProfile;
