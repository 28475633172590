import React, { useState, useEffect, useMemo } from 'react';
import { Link } from "react-router-dom";
import * as CS from '../../custom/componentStyle';
import timerClock from '../../assets/img/timerClock.png';
import { useAuthState } from "../../context/authContext";
import approvedBadge from '../../assets/img/approvedBadge.svg';
import { APP_COIN } from '../../utils/constants';
import { getUserName, prettyFormat } from '../../utils/helpers';

const NFT = ({ item, action }) => {
    const { user } = useAuthState();

    const [auctionStatus, setAuctionStatus] = useState('')
    const [auctionStatusMessage, setAuctionStatusMessage] = useState('')
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (item?.auction) setNewTime();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const saleAmount = useMemo(() => {
        if (!item) return BigInt('0');
        if (!user?.address) return BigInt('0');

        const pairAmount = item.pairs
            ?.filter((pair) => pair.owner.address === user.address.toLowerCase())
            ?.reduce((accum, pair) => (accum + pair.amount), BigInt('0')) || BigInt('0');
        const auctionAmount = item.auctions
            ?.filter((auction) => auction.owner.address === user.address.toLowerCase())
            ?.reduce((accum, auction) => (accum + auction.amount), BigInt('0')) || BigInt('0');
        return (pairAmount + auctionAmount);
    }, [item, user?.address]);

    const ownedAmount = useMemo(() => {
        if (!item?.owners?.length) return BigInt('0');
        if (!user?.address) return BigInt('0');

        return item.owners
            .filter((owner) => owner.address === user.address)
            .reduce((accum, owner) => accum + owner.amount, BigInt('0'));
    }, [item?.owners, user?.address]);

    const totalAmount = useMemo(() => {
        if (!item?.totalAmount) return BigInt('0');

        return BigInt(item.totalAmount);
    }, [item?.totalAmount]);

    const lowestPair = useMemo(() => {
        if (!item?.pairs || !item?.pairs?.length) return null;

        return item.pairs.sort((p1, p2) => p1 - p2)[0];
    }, [item?.pairs]);

    const setNewTime = () => {
        const currentTimestamp = new Date().getTime()
        let countdownDate = 0;
        if (item.auction.startTime * 1000 > currentTimestamp) {
            setAuctionStatusMessage('Auction Not Started')
            setAuctionStatus('NOT_STARTED')
        } else if (item.auction.endTime * 1000 > currentTimestamp) {
            setAuctionStatus('IN_PROGRESS')
            countdownDate = item.auction.endTime * 1000;
            setAuctionStatusMessage('Auction In Progress')
        } else {
            setAuctionStatusMessage('Auction Ended')
            setAuctionStatus('ENDED')
        }
        if (countdownDate) {
            const distanceToDate = countdownDate - currentTimestamp;
            let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
            let hours = Math.floor(
                (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            let minutes = Math.floor(
                (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
            );
            let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

            if (numbersToAddZeroTo.includes(days)) {
                days = `0${days}`;
            }
            if (numbersToAddZeroTo.includes(hours)) {
                hours = `0${hours}`;
            }
            if (numbersToAddZeroTo.includes(minutes)) {
                minutes = `0${minutes}`;
            }
            if (numbersToAddZeroTo.includes(seconds)) {
                seconds = `0${seconds}`;
            }
            setState({ days: days, hours: hours, minutes: minutes, seconds: seconds });
        }
    };

    return (
        <>
            {item && <div className="card">
                <div className="image-over" style={{ position: 'relative' }}>
                    <span style={CS.typeIcon}>
                        {
                            item.assetType === 'video' ? <CS.VideoIcon color={'white'} size={16} />
                                : item.assetType === 'audio' ? <CS.AudioIcon color={'white'} size={16} />
                                    : <CS.ImageIcon color={'white'} size={16} />
                        }
                    </span>
                    <span className={`nft-sale-status ${auctionStatus.toLowerCase()}`} style={{ display: item.auction ? "" : "none", ...CS.auctionTime }}>
                        {
                            auctionStatus == 'IN_PROGRESS' ?
                                <>
                                    <img src={timerClock} alt="TimerClock" style={{ marginRight: 3 }} />
                                    <span style={{ verticalAlign: 'middle' }}>{`${state.days || '00'}:${state.hours || '00'}:${state.minutes || '00'}:${state.seconds || '00'}`}</span>
                                </>
                                : `${auctionStatusMessage}`
                        }
                    </span>
                    <Link to={`/item-details/${item.itemCollection}/${item.tokenId}`}>
                        {item?.assetType === 'image' && <img className="card-img-top" style={CS.coverStyle} src={item.image} alt='' />}
                        {item?.assetType === 'audio' && <img className="card-img-top" style={CS.coverStyle} src={item.coverImage} alt='' />}
                        {item?.assetType === 'video' && <video className="card-img-top" style={CS.coverStyle} src={item.image} autoPlay loop />}
                    </Link>
                </div>
                {/* Card Caption */}
                <div className="card-caption col-12 p-0">
                    {/* Card Body */}
                    <div className="card-body">
                        <Link to={`/item-details/${item.itemCollection}/${item.tokenId}`}>
                            <h5 className="mb-0" title={item.name}>{item.name != '' ? item.name : '<no name>'}</h5>
                        </Link>

                        {item.creatorUser && (
                            <div className="row no-gutters mt-2">
                                <div className="seller item-seller col-7 d-flex flex-column">
                                    <span className="label mb-1">Created by</span>
                                    <Link to={`/account/${item.creatorUser.address}`}>
                                        <span className="nft-info-owner"><img className="rounded-circle" src={item.creatorUser.profilePic} alt="" style={{ background: '#fff', width: 18, height: 18 }} />
                                            {(item.creatorUser.isApproved && (
                                                <img className="nft-info-owner-verification" src={approvedBadge} alt="approved" />
                                            ))}
                                            &nbsp;&nbsp;
                                            {getUserName(item.creatorUser)}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        )}
                        <div className="row no-gutters mt-1">
                            <div className="item-pricing col-12 d-flex flex-column">
                                {
                                    lowestPair
                                        ? (
                                            <>
                                                <span className="label">Lowest Pair Price</span>
                                                <span className="nft-info-price" title={`${lowestPair.price} ${APP_COIN}`}>{prettyFormat(lowestPair.price, 18)} {APP_COIN}</span>
                                            </>
                                        )
                                        : (item.auction
                                            ? (<><span className="label">Highest Bid</span><span className="nft-info-price" title={`${item.auction.price} ${APP_COIN}`}>{item.auction.price} {APP_COIN}</span></>)
                                            : (<><span className="label">Info</span><span className="nft-info-price">Not for sale</span></>)
                                        )
                                }
                            </div>
                        </div>

                        <div className="row no-gutters mt-1">
                            {item?.type === 2 && ownedAmount !== 0 && (
                                <div className="item-pricing col-6 d-flex flex-column">
                                    <span className="label">Amount</span>
                                    <span className="nft-info-price">
                                        {action === 'onsale' ? (
                                            <>{`${saleAmount.toString()} / ${totalAmount.toString()}`}</>
                                        ) : (
                                            <>{`${ownedAmount.toString()} / ${totalAmount.toString()}`}</>
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="card-bottom">
                            {/* <span><i className="icon-heart mr-2" />{item.likeCount}</span> */}
                        </div>

                        <div style={{ textAlign: 'center' }}>
                            <Link className="btn btn-bordered btn-block btn-smaller mt-3" to={`/item-details/${item.itemCollection}/${item.tokenId}`}>
                                {action === 'owned' && (
                                    <>
                                        <i className="icon-handbag mr-2" />
                                        {'Place order'}
                                    </>
                                )}
                                {action === 'onsale' && lowestPair && (
                                    <>
                                        <i className="icon-handbag mr-2" />
                                        {(user && lowestPair && lowestPair?.owner?.address?.toLowerCase() === user?.address?.toLowerCase()) ? 'View' : 'Buy'}
                                    </>
                                )}
                                {action === 'onsale' && !lowestPair && (
                                    <>
                                        <i className="icon-handbag mr-2" />
                                        {'View item'}
                                    </>
                                )}
                                {action === 'created' && (
                                    <>
                                        <i className="icon-handbag mr-2" />
                                        {'View item'}
                                    </>
                                )}
                                {!action && (
                                    <>
                                        <i className="icon-handbag mr-2" />
                                        {'View item'}
                                    </>
                                )}
                                {/* <i className="icon-handbag mr-2" /> {lowestPair ? "Buy Now" : (item.auction ? "Place a Bid" : "Not for sale")} */}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>}
        </>

    );
}

export default NFT;
