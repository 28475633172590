export const APP_COIN = process.env.REACT_APP_COIN || 'pCKB';
export const NETWORK_ID = +process.env.REACT_APP_NETWORK_ID || 71401;
export const NETWORK = process.env.REACT_APP_NETWORK || 'Godwoken Network';
export const PROXY_HOST = process.env.REACT_APP_API_HOST || 'https://api.mylittlenfts.com';
export const NODES = [process.env.REACT_APP_NODE_1, process.env.REACT_APP_NODE_2, process.env.REACT_APP_NODE_3];
export const EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL || 'https://gw-explorer.nervosdao.community';
export const IPFS_URL = process.env.REACT_APP_IPFS_URL || 'https://ipfs.io/ipfs/';
export const PINATA_JWT_TOKEN = process.env.REACT_APP_PINATA_JWT_TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIzMmJkMDhmOS04ZDI0LTRiMTUtYTBkZC0wNGY1ZTMxZWU5NDkiLCJlbWFpbCI6ImFkbWluQGdvZHdva2VuLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiI0MTQzZjhhODQ4YzAzYWE4ZGRjNCIsInNjb3BlZEtleVNlY3JldCI6IjNlYTk1M2YzZTM4ZDBmMTI0YjcwMTc1NTc0ZGNlNWIxOTVjNWZhYzMxMjdiMjRiNjQzNTNjZjdlZmM3ZjRmNzgiLCJpYXQiOjE2NjMwODIxOTV9.uyoDX2LQ8ux0O9HJ5EQ3iDRR34tsDXsEpjhnJUMYtE8';
export const PROFILE_PICTURE_URL = 'https://godwoken.mypinata.cloud/ipfs/QmaxQGhY772ffG7dZpGsVoUWcdSpEV1APru95icXKmii67';
export const PROFILE_COVER_URL = 'https://godwoken.mypinata.cloud/ipfs/QmfHZw5sZucRfZvqfPW1MBLAeHJUb5k8m8V4jjD8tbtqwX';
export const NATION_FEE_ADDRESS = '0x78c47bf6daf128a6c8e16c2dc42fe38d5a6f4421';
