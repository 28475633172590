import React, { Component } from 'react';
import mainLogo from '../../assets/img/nftnation_logo.png';

class Footer extends Component {
    render() {
        return (
            <footer className="footer-area">
                {/* Footer Top */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-5 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Logo */}
                                    <a className="navbar-brand" href="/">
                                        <img src={mainLogo} alt="" />
                                    </a>
                                    <p className='tagline'>Explore, buy and sell NFTs. By the community, for the community.</p>
                                    {/* Social Icons */}
                                    <div className="social-icons d-flex">
                                        <a className={'twitter'} href="https://twitter.com/NFTxnation" target="_blank" rel="noreferrer">
                                            <i className={'fab fa-twitter'} />
                                            <i className={'fab fa-twitter'} />
                                        </a>
                                        <a className={'discord'} href="https://discord.gg/2DBVH3qgMf" target="_blank" rel="noreferrer">
                                            <i className={'fab fa-discord'} />
                                            <i className={'fab fa-discord'} />
                                        </a>
                                        <a className={'youtube'} href="http://youtube.com/NervosNation" target="_blank" rel="noreferrer">
                                            <i className={'fab fa-youtube'} />
                                            <i className={'fab fa-youtube'} />
                                        </a>
                                        <a className={'medium'} href=" https://medium.com/nervos-nation" target="_blank" rel="noreferrer">
                                            <i className={'fab fa-medium'} />
                                            <i className={'fab fa-medium'} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3 offset-lg-3 res-margin">
                                <div className="footer-items">
                                    <h4 className="footer-title">Useful Links</h4>
                                    <ul>
                                        <li><a href="/explore/collections">All NFTs</a></li>
                                        {/* <li><a href="#">How It Works</a></li> */}
                                        {/* <li><a href=".">Create</a></li> */}
                                        <li><a href="/explore/listed-items">Explore</a></li>
                                        {/* <li><a href="#">Privacy & Terms</a></li> */}
                                        <li><a href="https://nftnation.gitbook.io/nftnation-explained/ " target="_blank" rel="noreferrer">Gitbook</a></li>
                                        <li><a href="/bridge">Bridge</a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-12 col-sm-6 col-lg-4 res-margin">
                                <div className="footer-items">
                                    <h4 className="footer-title">Community</h4>
                                    <ul>
                                        <li><a href="#">Help Center</a></li>
                                        <li><a href="#">Partners</a></li>
                                        <li><a href=".">Suggestions</a></li>
                                        <li><a href="#">Blog</a></li>
                                        <li><a href="#">Newsletter</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-sm-6 col-lg-3">
                                <div className="footer-items">
                                    <h4 className="footer-title">Subscribe Us</h4>
                                    <div className="subscribe-form d-flex align-items-center">
                                        <input type="email" className="form-control" placeholder="info@yourmail.com" />
                                        <button type="submit" className="btn"><i className="icon-paper-plane" /></button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left">&copy; NFT Nation. All Rights Reserved.</div>
                                    {/* Copyright Right */}
                                    <div className="copyright-right">Powered with <i className="fas fa-heart" /> on <a href="https://godwoken.com/" target={'_blank'} rel="noreferrer">Godwoken</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
