import React from 'react';
import { Link } from "react-router-dom";
import * as CS from '../../custom/componentStyle';
import approvedBadge from '../../assets/img/approvedBadge.svg';

const AuthorItem = (props) => {
    const { author } = props;
    return (
        <>
            {author &&
                <div className="col-12 col-sm-6 col-lg-3 item">
                    <div className="card no-hover text-center">
                        <div className="image-over">
                            <Link to={`/account/${author.address}`}>
                                <img className="card-img-top" src={author.profileCover} alt="" style={CS.coverStyle} />
                            </Link>
                            {/* Seller */}
                            <Link className="seller" to={`/account/${author.address}`}>
                                <div className="seller-thumb avatar-lg">
                                    <img className="rounded-circle" src={author.profilePic} alt="" />
                                    {(author.isApproved && (
                                        <img className="seller-verification" src={approvedBadge} alt="approved" />
                                    ))}
                                </div>
                            </Link>
                        </div>
                        {/* Card Caption */}
                        <div className="card-caption col-12 p-0">
                            {/* Card Body */}
                            <div className="card-body mt-4">
                                <Link to={`/account/${author.address}`}>
                                    <h5>{author.name}</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default AuthorItem;
