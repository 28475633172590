import axios from 'axios'
import { logout, useAuthDispatch, useAuthState } from "../context/authContext";

import { PROXY_HOST } from '../utils/constants';

export function useAxios() {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();

  if (PROXY_HOST) {
    axios.defaults.baseURL = PROXY_HOST;
  }

  axios.interceptors.request.use(request => {
    if (token) {
      request.headers.common['Authorization'] = `Bearer ${token}`
    }

    request.timeout = 20000;

    return request
  })

  axios.interceptors.response.use(response => {
    return Promise.resolve(response);
  }, error => {
    console.error('error catching: ', error)
    const response = error?.response
    const status = response?.status

    if (status && status === 401) {
      logout(dispatch);
    }
    return Promise.reject(error)
  })
}
