import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AuthorItem from '../Item/AuthorItem';


const Authors = (props) => {
    const [items, setItems] = useState([]);
    const [noItems, setNoItems] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialItemsLoaded, setInitialItemsLoaded] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (initialItemsLoaded) setPage(1);
        setLoading(true);
        axios.get(`/authors?page=${page}`)
            .then(res => {
                if (!res.data.authors.length) {
                    setNoItems(true);
                    return;
                }
                if (page === 1) setItems(res.data.authors);
                else setItems(items.concat(res.data.authors));
                setLoading(false);
            }).catch(e=>{
                console.log(e);
                setLoading(false);
                setItems([...items]);
            })
    }, [page]);

    function loadMore() {
        if (!loading) {
            setInitialItemsLoaded(false);
            setPage(page + 1);
        }
    }
    return (
        <section className="popular-collections-area" style={{ paddingTop: 0 }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        {/* Intro */}
                        <div className="intro text-center">
                            <h3 className="mt-3 mb-0">Authors</h3>
                        </div>
                    </div>
                </div>
                <div className="row items">
                    {items.map((item, idx) => (<AuthorItem author={item} key={idx} />))}
                </div>
                <div className="load-more" style={{ display: noItems ? "none" : "", textAlign: 'center' }}>
                    <button onClick={() => loadMore()} className="btn mt-3 mt-sm-4">
                        {loading ? "Loading..." : "Load more"}
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Authors;
