import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CollectionItem from "../Item/CollectionItem";

const ExploreCollections = () => {
    const [collections, setCollections] = useState([]);
    const [noCollections, setNoCollections] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialCollectionsLoaded, setInitialCollectionsLoaded] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (initialCollectionsLoaded) setPage(1);
        setLoading(true);
        axios.get(`/collections?page=${page}`)
            .then(res => {
                if (!res.data.collections.length) {
                    setNoCollections(true);
                    return;
                }
                if (page === 1) setCollections(res.data.collections);
                else setCollections(collections.concat(res.data.collections));
                setLoading(false);
            }).catch(e => {
                console.log(e);
                setLoading(false);
                setCollections([...collections]);
                setNoCollections(true)
            })
    }, [page]);

    function loadMore() {
        if (!loading) {
            setInitialCollectionsLoaded(false);
            setPage(page + 1);
        }
    }
    return (
        <section className="popular-collections-area">
            <div className="container">
                <div className="row items">
                    {collections.map((collection, idx) => {
                        return (
                            <div key={`exf_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                                <CollectionItem collection={collection} />
                            </div>
                        );
                    })}
                </div>
                <div className="load-more" style={{ display: noCollections ? "none" : "", textAlign: 'center' }}>
                    <button onClick={() => loadMore()} className="btn mt-3 mt-sm-4">
                        {loading ? "Loading..." : "Load more"}
                    </button>
                </div>
            </div>
        </section>
    );
}

export default ExploreCollections;
