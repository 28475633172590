import React, { useState, useEffect } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { Dialog, Button } from '@mui/material';

import { connectors, connectorLocalStorageKey, getConnector } from './utils/connectors';
// import { useEagerConnect } from "./hooks/useEagerConnect";
import { setupNetwork } from './utils/wallet';
import { useInactiveListener } from "./hooks/useInactiveListener";
import { useAxios } from "./hooks/useAxios";
import { getErrorMessage } from "./utils/ethereum";

import { getUser, loginUser, useAuthDispatch, useAuthState } from "./context/authContext";

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes';

import './App.scss';

function App() {

  const [connectModalOpen, setConnectModalOpen] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(null);
  const [networkError, setNetworkError] = useState(null);

  useAxios();

  const { account, library, activate, active, connector } = useWeb3React();
  const connectAccount = () => {
    setConnectModalOpen(true)
  }
  const connectToProvider = (connector) => {
    let _tried = false;
    let _triedError = undefined;
    const connectorKey = window.localStorage.getItem(connectorLocalStorageKey);
    if (connectorKey && connectorKey !== "") {
      const currentConnector = getConnector(connectorKey)
      if (connectorKey === "injectedConnector") {
        currentConnector.isAuthorized().then((isAuthorized) => {
          if (isAuthorized) {
            activate(currentConnector, undefined, true).catch((error) => {
              if (error instanceof UnsupportedChainIdError) {
                setupNetwork().then((hasSetup) => {
                  if (hasSetup) activate(currentConnector);
                })
              }
              _triedError = error;
              _tried = true;
            })
          } else _tried = true;
        })
      } else {
        activate(currentConnector);
        _tried = true;
      }
    }
    if (_tried) {
      const errorMsg = getErrorMessage(_triedError);
      setNetworkError(errorMsg);
      setErrorModalOpen(true);
    }
    activate(connector)
  }

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState()
  // useEffect(() => {
  //   if (activatingConnector && activatingConnector === connector) {
  //     setActivatingConnector(undefined)
  //   }
  // }, [activatingConnector, connector])
  useEffect(() => {
    const connectorKey = window.localStorage.getItem(connectorLocalStorageKey);
    if (connectorKey && connectorKey !== "") {
      const currentConnector = getConnector(connectorKey)
      if (connectorKey === "injectedConnector") {
        currentConnector.isAuthorized().then((isAuthorized) => {
          if (isAuthorized) activate(currentConnector)
        })
      } else {
        activate(currentConnector);
      }
    } else if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [])

  // mount only once or face issues :P
  // const [triedEager, triedEagerError] = useEagerConnect()
  // const { activateError } = useInactiveListener(!triedEager || !!activatingConnector)

  // handling connection error
  // if ((triedEagerError || activateError) && errorModalOpen === null) {
  //   const errorMsg = getErrorMessage(triedEagerError || activateError);
  //   setNetworkError(errorMsg);
  //   setErrorModalOpen(true);
  // }
  const { activateError } = useInactiveListener(!!activatingConnector)
  if (activateError && errorModalOpen === null) {
    const errorMsg = getErrorMessage(activateError);
    setNetworkError(errorMsg);
    setErrorModalOpen(true);
  }

  const dispatch = useAuthDispatch();
  const { user, token } = useAuthState();

  const login = async () => {
    if (!account || !library) {
      return;
    }
    if (!user) {
      await getUser(dispatch, account);
    }
    if (!user?.nonce || token) {
      return;
    }
    loginUser(dispatch, account, user?.nonce, library.getSigner())
  }

  useEffect(() => {
    if (active && account) {
      getUser(dispatch, account)
    }
  }, [active, account]);

  const closeErrorModal = () => {
    window.localStorage.setItem(connectorLocalStorageKey, connectors[0].key);
    setErrorModalOpen(false);
    // window.location.reload();
  }

  return (
    <div>
      <MyRouts connectAccount={connectAccount} getUser={getUser} user={user} login={login} />

      <Dialog className='web3-modal' maxWidth={'xs'}
        // disableBackdropClick
        // disableEscapeKeyDown
        open={!!errorModalOpen && !active}
        onClose={(event, reason) => { if (reason && reason === "backdropClick") return; setErrorModalOpen(false) }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{ padding: '10%', textAlign: 'center', minWidth: '350px', background: '#f0f8ff' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{networkError}</p>
            <Button onClick={closeErrorModal} variant="contained" color="primary">Close</Button>
          </div>
        </div>
      </Dialog>

      <Dialog className='web3-modal' maxWidth={'xs'}
        // disableBackdropClick
        // disableEscapeKeyDown
        open={!!connectModalOpen}
        onClose={(event, reason) => { if (reason && reason === "backdropClick") return; setConnectModalOpen(false) }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{ padding: '10%', textAlign: 'center', minWidth: '350px', background: '#f0f8ff' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
              connectors.map((entry, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => {
                    connectToProvider(entry.connectorId);
                    window.localStorage.setItem(connectorLocalStorageKey, entry.key);
                    setConnectModalOpen(false)
                  }}
                  className="connect-button textPrimary"
                  color="primary"
                  style={{ color: 'red', marginBottom: '10px' }}
                  endIcon={<entry.icon width="30" />}
                  id={`wallet-connect-${entry.title.toLocaleLowerCase()}`}
                >
                  {entry.title}
                </Button>
              ))}
          </div>
          <div style={{ textAlign: 'center', paddingTop: 10 }}>
            <Button style={{ width: '100%' }} onClick={() => { setConnectModalOpen(false) }} variant="contained" color="primary">Close</Button>
          </div>

        </div>
      </Dialog>

    </div>
  );
}

export default App;
