import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NFT from "../Item/NFT";

const ExploreHome = () => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios.get(`/item?saleType=fixed&page=1&pageLimit=8`)
            .then(res => {
                setItems(res.data.items);
            }).catch(e => {
                console.log(e);
                setItems([]);
            })
    }, []);

    return (
        <section className="explore-area load-more p-0">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro d-flex justify-content-between align-items-end m-0">
                            <div className="intro-content">
                                <span>EXCLUSIVE ASSETS</span>
                                <h3 className="mt-3 mb-0">Explore</h3>
                            </div>
                            <div className="intro-btn mb-2">
                                <a className="btn content-btn" href="/explore/listed-items">View All</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row items">
                    {items.map((item, idx) => {
                        return (
                            <div key={`exo_${idx}`} className="col-12 col-sm-6 col-md-4 col-lg-3 item">
                                <NFT item={item} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default ExploreHome;
