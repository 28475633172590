import React, { useState, useEffect, useMemo } from 'react'
import { useParams, Link, useHistory } from "react-router-dom"
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { Snackbar, IconButton, CircularProgress } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import Modal from "react-modal"
import DatePicker from 'react-datepicker'
import { getTokenBalance, listItem, delistItem, buy, createAuction, finalizeAuction, bidOnAuction } from "../../utils/contracts"
import "react-datepicker/dist/react-datepicker.css"
import * as S from "./styles"
import ClockItem from "../Item/ClockItem"
import approvedBadge from '../../assets/img/approvedBadge.svg'
import DefaultNFTLogo from '../../assets/img/kaikas.png'
import { APP_COIN, EXPLORER_URL } from '../../utils/constants'
import { parseEther } from 'ethers'
import { getUserName, prettyFormat } from '../../utils/helpers'
import { getContractInfo } from '../../utils'

const ItemDetails = (props) => {
    let history = useHistory()
    const { collection, id } = useParams()
    const [item, setItem] = useState(null)
    const { account, chainId, library } = useWeb3React()
    const [balance, setBalance] = useState(0n)
    const [snackBarMessage, setSnackBarMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [showPlaceBidModal, setShowPlaceBidModal] = useState(false)
    const [showBuyNowModal, setShowBuyNowModal] = useState(false)
    const [showEndAuction, setShowEndAuction] = useState(false)
    const [showUnlistMarketPlace, setShowUnlistMarketPlace] = useState(false)
    const [showPutMarketPlace, setShowPutMarketPlace] = useState(false)
    const [bidPrice, setBidPrice] = useState(0)
    const [putType, setPutType] = useState('fixed')
    const [putPrice, setPutPrice] = useState(0n)
    const [startType, setStartType] = useState('now')
    const [startDate, setStartDate] = useState(null)
    const [endType, setEndType] = useState('1')
    const [endDate, setEndDate] = useState(null)
    const [listingStatus, setListingStatus] = useState(false)
    const [delistingStatus, setDelistingStatus] = useState(false)
    const [buyingStatus, setBuyingStatus] = useState(false)
    const [creatingAuctionStatus, setCreatingAuctionStatus] = useState(false)
    const [endingAuctionStatus, setEndingAuctionStatus] = useState(false)
    const [biddingStatus, setBiddingStatus] = useState(false)
    const [amount, setAmount] = useState(1)
    const [currentPair, setCurrentPair] = useState(null)

    const { address: marketAddress } = getContractInfo('NationMarket', chainId || null)

    /*
        Repetitive code below assigning colours, add to vars and re-use:

        Modal overlay: rgba(60, 57, 56, 0.85)
        Modal bg: rgba(222, 218, 221, 0.975)
    */
    const modalOverlayBgColor = 'rgba(60, 57, 56, 0.85)'
    const modalBgColor = 'rgba(222, 218, 221, 0.975)'
    const modalTextColor = 'black'

    useEffect(() => {
        if (!!account && !!library) {
            getTokenBalance(account, 'main', 18, chainId, library)
                .then((balance) => {
                    setBalance(parseFloat(balance.toString()))
                })
                .catch((e) => {
                    console.log("get token balance error: ", e)
                    setBalance(0n)
                })
        }
    }, [account, chainId, library])

    function fetchItem() {
        axios.get(`/item/${collection}/${id}`)
            .then(res => {
                setItem(res.data.item)
            })
            .catch(error => {
                console.error('Error fetching Item: ', error)
                setItem([])
            })
    }
    useEffect(() => {
        if (!item) {
            fetchItem()
        }
    }, [item])

    function openBuyModal(pair) {
        setCurrentPair(pair)
        setShowBuyNowModal(true)
    }

    function closeBuyModal() {
        setCurrentPair([])
        setAmount(1)
        setShowBuyNowModal(false)
    }

    function putOnMarketPlace() {
        if (putType === 'fixed') {
            putFixed()
        } else if (putType === 'timed') {
            putAuction()
        }
    }
    async function putFixed() {
        if (putPrice <= 0n) {
            setSnackBarMessage("Please input price correctly!")
            setOpenSnackbar(true)
            return
        }
        if (item?.type === 2 && !amount) {
            setSnackBarMessage("Please specify amount greater than 0")
            setOpenSnackbar(true)
            return
        }
        setListingStatus(true)

        listItem(
            item.itemCollection,
            account,
            item.tokenId,
            amount,
            putPrice,
            chainId,
            await library.getSigner(),
            item.type,
        ).then((tokenId) => {
            if (tokenId) {
                axios.get(`/sync_block`)
                    .then((res) => {
                        setListingStatus(false)
                        setShowPutMarketPlace(false)
                        setSnackBarMessage("Success")
                        setOpenSnackbar(true)
                        setAmount(1)
                        history.push(`/account/${account}`)
                        return true
                    })
                    .catch((error) => {
                        if (error.response) {
                            setListingStatus(false)
                            setSnackBarMessage(error.response.data.message)
                            setOpenSnackbar(true)
                        }
                    })
            } else {
                setListingStatus(false)
                setSnackBarMessage("Failed Transaction")
                setOpenSnackbar(true)
            }
        })
    }
    async function putAuction() {
        if (putPrice <= 0n) {
            setSnackBarMessage("Please input price correctly!")
            setOpenSnackbar(true)
            return
        }
        if (item?.type === 2 && !amount) {
            setSnackBarMessage("Please specify amount greater than 0")
            setOpenSnackbar(true)
            return
        }
        const currentTime = new Date().getTime()

        let startTimeStamp = 0
        if (startType === 'specific') {
            if (!startDate) {
                setSnackBarMessage("Please select start time.")
                setOpenSnackbar(true)
                return
            }
            const startTime = startDate.getTime()
            if (currentTime >= startTime) {
                setSnackBarMessage("The start time must be after the current time.")
                setOpenSnackbar(true)
                return
            }
            startTimeStamp = Math.floor(startTime / 1000)
        } else {
            startTimeStamp = Math.floor(currentTime / 1000)
        }

        let endTimeStamp = 0
        if (endType === 'specific') {
            if (!endDate) {
                setSnackBarMessage("Please select end time.")
                setOpenSnackbar(true)
                return
            }
            const endTime = endDate.getTime()
            endTimeStamp = Math.floor(endTime / 1000)
            if (currentTime >= endTime) {
                setSnackBarMessage("The end time must be after the current time.")
                setOpenSnackbar(true)
                return
            }
            if (startTimeStamp >= endTimeStamp) {
                setSnackBarMessage("The end time must be after the start time.")
                setOpenSnackbar(true)
                return
            }
        } else {
            const later = Number(endType)
            endTimeStamp = startTimeStamp + 86400 * later
        }
        console.log("endTimeStamp")
        console.log(endTimeStamp)


        setCreatingAuctionStatus(true)
        createAuction(
            item.itemCollection,
            account,
            item.tokenId,
            amount,
            putPrice,
            startTimeStamp,
            endTimeStamp,
            chainId,
            await library.getSigner(),
            item.type,
        ).then((tokenId) => {
            if (tokenId) {
                axios.get(`/sync_block`)
                    .then((res) => {
                        setCreatingAuctionStatus(false)
                        setShowPutMarketPlace(false)
                        setSnackBarMessage("Success")
                        setOpenSnackbar(true)
                        setAmount(1)
                        history.push(`/account/${account}`)
                        return true
                    })
                    .catch((error) => {
                        if (error.response) {
                            setCreatingAuctionStatus(false)
                            setSnackBarMessage(error.response.data.message)
                            setOpenSnackbar(true)
                        }
                    })
            } else {
                setCreatingAuctionStatus(false)
                setSnackBarMessage("Failed Transaction")
                setOpenSnackbar(true)
            }
            setAmount(1)
        })
    }
    async function endAuction() {
        if (!firstAuction) return

        setEndingAuctionStatus(true)

        finalizeAuction(
            firstAuction.id,
            chainId,
            await library.getSigner()
        ).then((result) => {
            if (result) {
                axios.get(`/sync_block`)
                    .then((res) => {
                        setEndingAuctionStatus(false)
                        setShowEndAuction(false)
                        setSnackBarMessage("Success")
                        setOpenSnackbar(true)
                        setAmount(1)
                        history.push(`/account/${account}`)
                        return true
                    })
                    .catch((error) => {
                        if (error.response) {
                            setEndingAuctionStatus(false)
                            setSnackBarMessage(error.response.data.message)
                            setOpenSnackbar(true)
                        }
                    })
            } else {
                setEndingAuctionStatus(false)
                setSnackBarMessage("Failed Transaction")
                setOpenSnackbar(true)
            }
        })

    }
    async function unlistItem(pair) {
        if (!pair) return

        setDelistingStatus(true)

        delistItem(
            pair.id,
            chainId,
            await library.getSigner(),
            item.type
        ).then((result) => {
            if (result) {
                axios.get(`/sync_block`)
                    .then((res) => {
                        setDelistingStatus(false)
                        setShowUnlistMarketPlace(false)
                        setSnackBarMessage("Success")
                        setOpenSnackbar(true)
                        setAmount(1)
                        history.push(`/account/${account}`)
                        return true
                    })
                    .catch((error) => {
                        if (error.response) {
                            setDelistingStatus(false)
                            setSnackBarMessage(error.response.data.message)
                            setOpenSnackbar(true)
                        }
                    })
            } else {
                setDelistingStatus(false)
                setSnackBarMessage("Failed Transaction")
                setOpenSnackbar(true)
            }
        })


    }
    async function buyItem(pair) {
        if (!pair) {
            closeBuyModal()
            setSnackBarMessage("Pair not set!")
            setOpenSnackbar(true)
            return
        }
        if (amount === 0) {
            closeBuyModal()
            setSnackBarMessage("Amount could not be zero!")
            setOpenSnackbar(true)
            return
        }
        const b = parseEther(parseInt(balance).toString())
        const price = BigInt(pair.price) * BigInt(amount)
        if (b <= price) {
            closeBuyModal()
            setSnackBarMessage("Your available balance is less than the price!")
            setOpenSnackbar(true)
            return
        }
        if (BigInt(pair.amount) < BigInt(amount)) {
            closeBuyModal()
            setSnackBarMessage("Amount greater than pair!")
            setOpenSnackbar(true)
            return
        }

        setBuyingStatus(true)
        buy(
            pair.market,
            pair.id,
            amount,
            price.toString(),
            chainId,
            await library.getSigner()
        ).then((tokenId) => {
            if (tokenId) {
                axios.get(`/sync_block`)
                    .then((res) => {
                        setBuyingStatus(false)
                        closeBuyModal()
                        setSnackBarMessage("Success")
                        setOpenSnackbar(true)
                        history.push(`/account/${account}`)
                        return true
                    })
                    .catch((error) => {
                        if (error.response) {
                            setBuyingStatus(false)
                            setSnackBarMessage(error.response.data.message)
                            setOpenSnackbar(true)
                        }
                    })
            } else {
                setBuyingStatus(false)
                setSnackBarMessage("Failed Transaction")
                setOpenSnackbar(true)
            }
        })
    }
    async function placeBid() {
        if (!firstAuction) {
            setSnackBarMessage("Auction not found!")
            setOpenSnackbar(true)
            return
        }
        if (!bidPrice) {
            setSnackBarMessage("Bid price not set!")
            setOpenSnackbar(true)
            return
        }
        let highestBidPrice = BigInt(highestBid?.price || 0) * BigInt(firstAuction.amount)
        if (highestBidPrice === 0n) {
            highestBidPrice = BigInt(firstAuction.startPrice || 0) * BigInt(firstAuction.amount)
        }
        const newBidPrice = BigInt(bidPrice * (10 ** 18)) + highestBidPrice
        const lowestPricePossible = highestBidPrice * 107n

        if (newBidPrice - lowestPricePossible < 0) {
            setSnackBarMessage("Your bid must be 7% higher than current bid!")
            setOpenSnackbar(true)
            return
        }

        if (balance < newBidPrice) {
            setSnackBarMessage("Your available balance is less than the bid price!")
            setOpenSnackbar(true)
            return
        }

        setBiddingStatus(true)

        bidOnAuction(
            firstAuction.id,
            newBidPrice / firstAuction.amount,
            newBidPrice,
            chainId,
            await library.getSigner()
        ).then((result) => {
            if (result) {
                axios.get(`/sync_block`)
                    .then((res) => {
                        setBiddingStatus(false)
                        closePlaceBidModal()
                        setSnackBarMessage("Success")
                        setOpenSnackbar(true)
                        fetchItem()
                        setAmount(1)
                        return true
                    })
                    .catch((error) => {
                        if (error.response) {
                            setBiddingStatus(false)
                            setSnackBarMessage(error.response.data.message)
                            setOpenSnackbar(true)
                        }
                    })
            } else {
                setBiddingStatus(false)
                setSnackBarMessage("Failed Transaction")
                setOpenSnackbar(true)
            }
        })

    }
    function closePlaceBidModal() {
        setShowPlaceBidModal(false)
        setBidPrice(0)
    }
    const dateFormat = (timestapm) => {
        let dateString = new Date(timestapm * 1000).toISOString().slice(0, 10) + " " + new Date(timestapm * 1000).toISOString().slice(11, 19)
        return dateString
    }
    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') return
        setOpenSnackbar(false)
    }
    function fixPriceValidator(value) {
        if (!isNaN(parseFloat(value))) value = parseFloat(value).toString()
        setPutPrice(value)
    }
    function bidPriceValidator(value) {
        if (!isNaN(parseFloat(value))) value = parseFloat(value).toString()
        setBidPrice(value)
    }
    function fixAmountValidator(value, checkAmount) {
        console.log('fixAmountValidator 1', value)
        console.log('fixAmountValidator 2', checkAmount)
        if (value === '') {
            setAmount(1)
            return
        }
        let amount = Number(value || 0)
        if (Number(checkAmount) < amount) return
        setAmount(amount)
    }

    const itemOwner = useMemo(() => {
        if (!item?.owners) return null
        if (!props?.user?.address) return null

        return item.owners.find((owner) => {
            const userAddress = props.user.address.toLowerCase()
            const auctionOwner = item?.auctions[0]?.owner?.address?.toLowerCase()

            if(owner.address.toLowerCase() === userAddress) {
                return true
            }

            if(auctionOwner && auctionOwner === userAddress) {
                return true
            }

            return false
        })
    }, [props?.user, item?.owners])

    const pairOwned = useMemo(() => {
        if (!item?.pairs) return null
        if (!props?.user?.address) return null

        return item.pairs.find((pair) => pair.owner.address.toLowerCase() === props.user.address.toLowerCase())
    }, [props?.user, item?.pairs])

    const lowestPair = useMemo(() => {
        if (!item?.pairs || !item?.pairs?.length) return null

        return item.pairs.sort((p1, p2) => +p1.amount - +p2.amount)[0]
    }, [item?.pairs])

    const ownedAmount = useMemo(() => {
        console.log('ownedAmount 1:', itemOwner)

        if (!itemOwner?.amount) return 0

        return itemOwner.amount
    }, [itemOwner])

    const pairAmount = useMemo(() => {
        if (!pairOwned?.amount) return 0

        return pairOwned.amount
    }, [pairOwned])

    // TODO: Add multiple auctions
    const firstAuction = useMemo(() => {
        if (!item?.auctions) return null

        return item.auctions.sort((a1, a2) => a1.createdAt - a2.createdAt < 0 ? -1 : 1)[0]
    }, [item?.auctions])

    const highestBid = useMemo(() => {
        if (!firstAuction) return null

        return firstAuction.bids.sort((b1, b2) => b1.price - b2.price < 0 ? 1 : -1)[0]
    }, [firstAuction])

    return (
        <section className="item-details-area">
            {item && <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-sm-6 col-md-5 col-lg-5">
                        <div className="item-info">
                            <div className="item-thumb text-center">
                                {
                                    item.assetType === 'video' ? <video src={item.image} autoPlay loop controls style={{ width: '100%', maxHeight: 550, borderRadius: 10 }} onError={(e) => {
                                        e.target.onerror = null
                                        e.target.src = DefaultNFTLogo
                                    }} />
                                        : item.assetType === 'image' ? <img src={item.image} onError={(e) => {
                                            e.target.onerror = null
                                            e.target.src = DefaultNFTLogo
                                        }} style={{ width: '100%', maxHeight: 550, borderRadius: 10 }} />
                                            : <>
                                                <img src={item.coverImage} style={{ width: '100%', maxHeight: 550, borderRadius: 10 }} onError={(e) => {
                                                    e.target.onerror = null
                                                    e.target.src = DefaultNFTLogo
                                                }} />
                                                <audio src={item.image} autoPlay loop controls style={{ width: '100%', marginTop: 10 }} />
                                            </>
                                }
                            </div>
                            {firstAuction && <ClockItem startTime={firstAuction.startAt} endTime={firstAuction.endAt} />}
                            {/* Netstorm Tab */}
                            <ul className="netstorm-tab nav nav-tabs mt-4" id="nav-tab">
                                <li>
                                    <a className="" id="nav-property-tab" data-toggle="pill" href="#nav-property">
                                        <h5 className="m-0">Properties</h5>
                                    </a>
                                </li>
                                <li>
                                    <a className="active" id="nav-owner-tab" data-toggle="pill" href="#nav-owner">
                                        <h5 className="m-0">Owners</h5>
                                    </a>
                                </li>
                                {firstAuction && <li>
                                    <a className="" id="nav-home-tab" data-toggle="pill" href="#nav-home">
                                        <h5 className="m-0">Bids</h5>
                                    </a>
                                </li>}
                                <li>
                                    <a className="" id="nav-profile-tab" data-toggle="pill" href="#nav-profile">
                                        <h5 className="m-0">History</h5>
                                    </a>
                                </li>
                            </ul>
                            {/* Tab Content */}
                            <div className="tab-content" id="nav-tabContent">
                                <div className={`tab-pane fade show`} id="nav-property">
                                    <div className="row items">
                                        {!item.propertyTypes && (
                                            <p>Properties not found</p>
                                        )}
                                        {item.propertyTypes.map((property_type, pid) => (
                                            <div className="col-12 col-md-6 item px-lg-2" key={`proptype-${property_type}`}>
                                                <div className="card no-hover" style={{ padding: 16 }}>
                                                    <div className="single-seller d-flex align-items-center">
                                                        <div className="seller-info ml-1" style={{ textAlign: 'center', width: '100%' }}>
                                                            <span style={{ wordBreak: 'break-all', fontSize: 12 }}>{property_type}</span>
                                                            <span style={{ wordBreak: 'break-all', color: 'black' }}>{item.propertyValues[pid]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={`tab-pane fade show active`} id="nav-owner">
                                    {!item.owners?.length && !item.pairs?.length && (
                                        <p>Owners not found</p>
                                    )}
                                    {item.pairs && item.pairs.sort((p1, p2) => BigInt(p1.price) - BigInt(p2.price) < 0 ? -1 : 1).map((pair, idx) => (
                                        <div className="row mt-3" style={{ width: '100%' }} key={`pair-link-${pair.owner.address}-${idx}`}>
                                            <div className="col-2 mr-0 pr-0 ml-0 pl-0">
                                                <Link to={`/account/${pair.owner.address}`}>
                                                    <div>
                                                        <img className="rounded-circle" src={pair.owner.profilePic} alt="" style={{ background: '#fff', height: 35, width: 35 }} />
                                                        {(pair.owner.isApproved && (
                                                            <img className="owner-verification" src={approvedBadge} alt="approved" />
                                                        ))}
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-7 ml-0 pl-0">
                                                <Link to={`/account/${pair.owner.address}`}>
                                                    <h6 className="mt-0 mb-0">{getUserName(pair.owner)}</h6>
                                                </Link>
                                                <p className="mt-1">
                                                    {`${pair.amount}/${item.totalAmount} on sale for ${prettyFormat(pair.price, 18)} ${APP_COIN} each`}
                                                </p>
                                            </div>
                                            <div className="col-3 ml-0 pl-0">
                                                {pair.owner.address !== props.user?.address && (
                                                    <div className="btn" style={{ textAlign: 'center', widht: 10 }}
                                                        onClick={() => openBuyModal(pair)}>Buy</div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {/* TODO: Add bids */}
                                    {item.owners && item.owners.filter((owner) => owner.address.toLowerCase() !== marketAddress.toLowerCase()).map((owner, idx) => (
                                        <div className="row mt-3" style={{ width: '100%' }} key={`owned-link-${owner.address}-${idx}`}>
                                            <div className="col-2 mr-0 pr-0 ml-0 pl-0">
                                                <Link to={`/account/${owner.address}`}>
                                                    <div>
                                                        <img className="rounded-circle" src={owner.profilePic} alt="" style={{ background: '#fff', height: 35, width: 35 }} />
                                                        {(owner.isApproved && (
                                                            <img className="owner-verification" src={approvedBadge} alt="approved" />
                                                        ))}
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-7 ml-0 pl-0">
                                                <Link to={`/account/${owner.address}`}>
                                                    <h6 className="mt-0 mb-0">
                                                        {getUserName(owner)}
                                                    </h6>
                                                </Link>
                                                <p className="mt-1">{owner.amount} {owner.amount === 1 ? 'Edition' : 'Editions'}</p>
                                            </div>
                                            <div className="col-3 ml-0 pl-0"></div>
                                        </div>
                                        // <Link key={`owned-link-${owner.address}-${idx}`} className="owner-meta d-flex align-items-center ml-3" to={`/account/${owner.address}`}>
                                        //     <div>
                                        //         <img className="rounded-circle" src={owner.profilePic} alt="" style={{ background: '#fff', height: 35, width: 35 }} />
                                        //         {(owner.isApproved && (
                                        //             <img className="owner-verification" src={approvedBadge} alt="approved" />
                                        //         ))}
                                        //     </div>
                                        //     <h6 className="ml-2">
                                        //         {getUserName(owner)}
                                        //         <p>{owner.amount} Edition(s)</p>
                                        //     </h6>
                                        // </Link>
                                    ))}
                                </div>
                                <div className={`tab-pane fade show`} id="nav-home">
                                    <ul className="list-unstyled" style={{ maxHeight: 400, overflowX: 'auto' }}>
                                        {!firstAuction?.bids?.length && (
                                            <li className="single-tab-list d-flex align-items-center"><p>Bids not found</p></li>
                                        )}
                                        {firstAuction?.bids?.length > 0 && firstAuction.bids.map((bid, idx) => (
                                            <li className="single-tab-list d-flex align-items-center" key={idx}>
                                                <p className="m-0">Bids listed for &nbsp;
                                                    <strong>{prettyFormat(bid.price, 18)} {APP_COIN}</strong>
                                                    &nbsp; at &nbsp;{dateFormat(bid.createdAt)}
                                                    <br />by <a href="/author">@{getUserName(bid.from)}</a></p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={`tab-pane fade show`} id="nav-profile">
                                    <ul className="list-unstyled" style={{ maxHeight: 450, overflowX: 'auto' }}>
                                        {!item.events?.length && (
                                            <li className="single-tab-list d-flex align-items-center"><p>History not found</p></li>
                                        )}
                                        {item.events && item.events.map((event, index) => (
                                            <li className="single-tab-list d-flex align-items-center" key={index}>
                                                {event.name === "MarketListed" &&
                                                    <p className="m-0">
                                                        <a href={`${EXPLORER_URL}/tx/${event.txHash}`} target="_blank" rel="noreferrer">Listed</a>{(+event.amount > 0 ? ` with ${event.amount} item(s)` : '')} for
                                                        <strong> {event.price} {APP_COIN}</strong> at {dateFormat(event.timestamp)}
                                                        <br />by <Link to={`/account/${event.userFrom.address}`}>@{event.userFrom.name}</Link></p>}
                                                {event.name === "MarketDelisted" &&
                                                    <p className="m-0">
                                                        <a href={`${EXPLORER_URL}/tx/${event.txHash}`} target="_blank" rel="noreferrer">Delisted</a>{(+event.amount > 0 ? ` with ${event.amount} item(s)` : '')} at {dateFormat(event.timestamp)}
                                                        <br />by <Link to={`/account/${event.userTo.address}`}>@{event.userTo.name}</Link></p>}
                                                {event.name === "Minted" &&
                                                    <p className="m-0">
                                                        <a href={`${EXPLORER_URL}/tx/${event.txHash}`} target="_blank" rel="noreferrer">Minted</a>{(+event.amount > 0 ? ` with ${event.amount} item(s)` : '')} at {dateFormat(event.timestamp)}
                                                        <br />by <Link to={`/account/${event.userTo.address}`}>@{event.userTo.name}</Link></p>}
                                                {(event.name === "MarketSold" || event.name === "AuctionSold") &&
                                                    <p className="m-0">
                                                        <a href={`${EXPLORER_URL}/tx/${event.txHash}`} target="_blank" rel="noreferrer">Sold</a>{(+event.amount > 0 ? ` with ${event.amount} item(s)` : '')} for
                                                        <strong> {event.price} {APP_COIN}</strong> at {dateFormat(event.timestamp)}
                                                        <br />by <Link to={`/account/${event.userTo.address}`}>@{event.userTo.name}</Link></p>}
                                                {event.name === "AuctionListed" &&
                                                    <p className="m-0">
                                                        <a href={`${EXPLORER_URL}/tx/${event.txHash}`} target="_blank" rel="noreferrer">Auction Created</a>{(+event.amount > 0 ? ` with ${event.amount} item(s)` : '')} for
                                                        <strong> {event.price} {APP_COIN}</strong> at {dateFormat(event.timestamp)}
                                                        <br />by <Link to={`/account/${event.userFrom.address}`}>@{event.userFrom.name}</Link></p>}
                                                {event.name === "AuctionDelisted" &&
                                                    <p className="m-0">
                                                        <a href={`${EXPLORER_URL}/tx/${event.txHash}`} target="_blank" rel="noreferrer">Auction Canceled</a>{(+event.amount > 0 ? ` with ${event.amount} item(s)` : '')} at {dateFormat(event.timestamp)}
                                                        <br />by <Link to={`/account/${event.userTo.address}`}>@{event.userTo.name}</Link></p>}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-7 col-lg-7">
                        {/* Content */}
                        <div className="content mt-1 mt-lg-0">
                            <h3 className="m-0">{item.name}</h3>
                            <p>{item.description}</p>
                            {/* Creator */}
                            {/* <div className="owner d-flex align-items-center">
                                <span>Creator</span>
                                <Link className="owner-meta d-flex align-items-center ml-3" to={`/account/${item.owner.address}`}>
                                    <div>
                                        <img className="rounded-circle" src={item.owner.profilePic} alt="" style={{ background: '#fff', height: 35, width: 35 }} />
                                        {(item.owner.isApproved && (
                                            <img className="owner-verification" src={approvedBadge} alt="approved" />
                                        ))}
                                    </div>
                                    <h6 className="ml-2">{item.owner.name}</h6>
                                </Link>
                                {item.owners.map((owner) => (
                                    <Link key={`owned-link-${owner.address}`} className="owner-meta d-flex align-items-center ml-3" to={`/account/${owner.address}`}>
                                        <div>
                                            <img className="rounded-circle" src={owner.profilePic} alt="" style={{ background: '#fff', height: 35, width: 35 }} />
                                            {(owner.isApproved && (
                                                <img className="owner-verification" src={approvedBadge} alt="approved" />
                                            ))}
                                        </div>
                                        <h6 className="ml-2">{owner.name}</h6>
                                    </Link>
                                ))}
                            </div> */}

                            {/* Item Info List */}
                            <div className="item-info-list mt-4">
                                <ul className="list-unstyled">
                                    {/* <li className="price d-flex justify-content-between"> */}
                                    <h4>
                                        {
                                            lowestPair
                                                ? (
                                                    <span>{prettyFormat(lowestPair.price, 18)} {APP_COIN}</span>
                                                )
                                                : (firstAuction
                                                    ? (
                                                        <>
                                                            {highestBid ? (
                                                                <span>{prettyFormat(highestBid.price, 18)} {APP_COIN}</span>
                                                            ) : (
                                                                <span>No bids at this moment</span>
                                                            )}
                                                        </>
                                                    )
                                                    : "Not for sale")
                                        }
                                        {/* <span><i className="icon-heart mr-2" style={{ cursor: 'pointer' }} />{item.likeCount}</span> */}
                                    </h4>
                                    {/* </li> */}
                                </ul>
                            </div>

                            <div className="row items">
                                <div className="col-12 col-md-6 px-lg-2 mb-2">
                                    <div className="d-flex align-items-center">
                                        <span>Category:</span>
                                        <div className="d-flex align-items-center ml-3"><h5 className="ml-2">{item.category}</h5></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{ width: '100%' }}>
                                <div className="col-12 col-md-6 px-lg-2 mb-2">
                                    <div className="card no-hover" style={{ padding: '0.5rem' }}>
                                        <div className="seller-info text-center">
                                            <div style={{ fontSize: 12 }}>Collection Royalty</div>
                                            <div style={{ color: 'black' }}>{item?.collection?.royalty ? parseInt(item.collection.royalty) / 10 : '0'} %</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 px-lg-2 mb-2">
                                    <div className="card no-hover" style={{ padding: '0.5rem' }}>
                                        <div className="seller-info text-center">
                                            <div style={{ fontSize: 12 }}>Item Royalty</div>
                                            <div style={{ color: 'black' }}>{item?.royalty ? parseInt(item.royalty) / 10 : '0'} %</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row items">
                                <div className="col-12 col-md-6 item px-lg-2">
                                    <div className="card no-hover">
                                        <div className="single-seller d-flex align-items-center">
                                            <Link to={`/account/${item?.creatorUser.address}`}>
                                                <div>
                                                    <img className="avatar-md rounded-circle" src={item.creatorUser.profilePic} alt="" style={{ background: '#fff' }} />
                                                    {(item.creatorUser.isApproved && (
                                                        <img className="avatar-verification" src={approvedBadge} alt="approved" />
                                                    ))}
                                                </div>
                                            </Link>
                                            <div className="seller-info ml-3">
                                                <Link className="seller mb-2" to={`/account/${item.creatorUser.address}`}>{getUserName(item.creatorUser)}</Link>
                                                <span>Creator</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 item px-lg-2">
                                    <div className="card no-hover">
                                        <div className="single-seller d-flex align-items-center">
                                            <Link to={`/collections/${item?.collection.address}`}>
                                                <img
                                                    className="avatar-md rounded-circle"
                                                    src={item.collection.image}
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = DefaultNFTLogo
                                                    }}
                                                    alt=""
                                                    style={{ background: '#fff' }} />
                                            </Link>
                                            <div className="seller-info ml-3">
                                                <Link to={`/collections/${item?.collection.address}`} className="seller mb-2">{item.collection.name}</Link>
                                                <span>Collection</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {highestBid &&
                                    <div className="col-12 item px-lg-2">
                                        <div className="card no-hover">
                                            <div className="price d-flex justify-content-between align-items-center">
                                                <h5 className="m-0">Highest Bid</h5>
                                                <span>{prettyFormat(highestBid.price, 18)} {APP_COIN}</span>
                                            </div>
                                        </div>
                                    </div>}
                            </div>

                            {
                                item && props.user && account ?
                                    <>
                                        {itemOwner ?
                                            <>
                                                {ownedAmount > 0 && <div className="d-block btn btn-solid-green mt-4"
                                                    onClick={() => setShowPutMarketPlace(true)}>Put on marketplace</div>}
                                                {firstAuction && props.user?.address?.toLowerCase() === firstAuction.owner.address.toLowerCase() && <div className="d-block btn btn-solid-green mt-4"
                                                    onClick={() => setShowEndAuction(true)}>End Auction</div>}
                                                {pairAmount > 0 && <div className="d-block btn btn-solid-warn mt-4"
                                                    onClick={() => setShowUnlistMarketPlace(true)}>Unlist from marketplace</div>}
                                                {firstAuction && props.user?.address?.toLowerCase() !== firstAuction.owner.address.toLowerCase() && <div className="d-block btn btn-solid-green mt-4"
                                                    onClick={() => setShowPlaceBidModal(true)}>Place a Bid</div>}
                                            </>
                                            :
                                            <>
                                                {firstAuction && props.user?.address?.toLowerCase() !== firstAuction.owner.address.toLowerCase() && <div className="d-block btn btn-solid-green mt-4"
                                                    onClick={() => setShowPlaceBidModal(true)}>Place a Bid</div>}
                                                {lowestPair && lowestPair.owner.address.toLowerCase() !== props.user.address.toLowerCase() && <div className="d-block btn btn-solid-green mt-4"
                                                    onClick={() => openBuyModal(lowestPair)}>Buy with lowest price</div>}
                                            </>
                                        }
                                    </>
                                    : <></>
                            }
                            {!props?.user?.address && (lowestPair || firstAuction) && (
                                <div className="content mt-4 mt-lg-6 text-center">
                                    <b>Connect Wallet to login and view purchasing options.</b>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >}
            {/* Put on Marketplace Modal */}
            <Modal
                isOpen={showPutMarketPlace}
                onRequestClose={() => setShowPutMarketPlace(false)}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: modalOverlayBgColor,
                        zIndex: 99,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '500px',
                        borderRadius: '20px',
                        backgroundColor: modalBgColor,
                        color: modalTextColor,
                        zIndex: 9999
                    },
                }}
            >
                <S.ModalBody>
                    <S.ModalHeader>
                        <S.ModalCloseIcon size={32} onClick={() => setShowPutMarketPlace(false)} />
                    </S.ModalHeader>
                    <S.ModalTitle>Put on Marketplace</S.ModalTitle>
                    <S.PutTypes>
                        <S.PutType onClick={() => setPutType('fixed')} className={putType === 'fixed' ? 'active' : ''}>
                            <S.FixedIcon size={32} />
                            <S.TypeLabel>Fixed price</S.TypeLabel>
                        </S.PutType>
                        {!firstAuction && (
                            <S.PutType onClick={() => setPutType('timed')} className={putType === 'timed' ? 'active' : ''}>
                                <S.TimeIcon size={36} />
                                <S.TypeLabel>Timed auction</S.TypeLabel>
                            </S.PutType>
                        )}
                    </S.PutTypes>
                    {
                        item?.type === 2 &&
                        <S.Field>
                            <S.label>{`Amount (max: ${ownedAmount.toString()})`}</S.label>
                            <S.InputContainer>
                                <S.Input type={"number"} placeholder={`Enter Amount`} onChange={event => fixAmountValidator(event.target.value, ownedAmount)}
                                    value={amount}
                                    onKeyPress={(event) => {
                                        if (!/^[0-9]*?$/.test(amount + event.key)) {
                                            event.preventDefault()
                                        }
                                    }} />
                            </S.InputContainer>
                        </S.Field>
                    }
                    {
                        putType === 'fixed' &&
                        <S.Field>
                            <S.label>Price ({APP_COIN})</S.label>
                            <S.InputContainer>
                                <S.Input type={"number"} placeholder={"Enter Price"} onChange={event => fixPriceValidator(event.target.value)}
                                    value={putPrice.toString()}
                                    onKeyPress={(event) => {
                                        if (!/^[0-9]*(\.[0-9]{0,2})?$/.test(putPrice + event.key)) {
                                            event.preventDefault()
                                        }
                                    }} />
                            </S.InputContainer>
                        </S.Field>
                    }
                    {
                        putType === 'timed' &&
                        <>
                            <S.Field>
                                <S.label>Minimum bid ({APP_COIN})</S.label>
                                <S.InputContainer>
                                    <S.Input type={"number"} placeholder={"Enter minimum bid"} onChange={event => fixPriceValidator(event.target.value)}
                                        value={putPrice.toString()}
                                        onKeyPress={(event) => {
                                            if (!/^[0-9]*(\.[0-9]{0,2})?$/.test(putPrice + event.key)) {
                                                event.preventDefault()
                                            }
                                        }} />
                                </S.InputContainer>
                            </S.Field>
                            <S.SelectRow>
                                <S.SelectField>
                                    <S.label>Starting Date</S.label>
                                    <S.StartingDateSelect name={"starting_date"} defaultValue={startType} onChange={event => setStartType(event.target.value)}>
                                        <S.OrderByOption value={"now"}>Right after listing</S.OrderByOption>
                                        <S.OrderByOption value={"specific"}>Pick specific date</S.OrderByOption>
                                    </S.StartingDateSelect>
                                    {
                                        startType === "specific" &&
                                        <DatePicker
                                            selected={startDate}
                                            onChange={value => setStartDate(value)}
                                            className={"input-picker"}
                                            showTimeSelect
                                            dateFormat="Pp"
                                        />
                                    }
                                </S.SelectField>
                                <S.SelectField>
                                    <S.label>Expiration Date</S.label>
                                    <S.StartingDateSelect name={"expiration_date"} defaultValue={endType} onChange={event => setEndType(event.target.value)}>
                                        <S.OrderByOption value={"1"}>1 day</S.OrderByOption>
                                        <S.OrderByOption value={"3"}>3 days</S.OrderByOption>
                                        <S.OrderByOption value={"5"}>5 days</S.OrderByOption>
                                        <S.OrderByOption value={"7"}>7 days</S.OrderByOption>
                                        <S.OrderByOption value={"specific"}>Pick specific date</S.OrderByOption>
                                    </S.StartingDateSelect>
                                    {
                                        endType === "specific" &&
                                        <DatePicker
                                            selected={endDate}
                                            onChange={value => setEndDate(value)}
                                            className={"input-picker"}
                                            showTimeSelect
                                            dateFormat="Pp"
                                        />
                                    }
                                </S.SelectField>
                            </S.SelectRow>
                        </>
                    }
                    <S.ModalActions>
                        <button className="btn mt-3 mt-sm-4 w-100 mr-2" onClick={() => setShowPutMarketPlace(false)}>Cancel</button>
                        {
                            listingStatus || creatingAuctionStatus ? <button className='btn mt-3 mt-sm-4 w-100 ml-2'>
                                <CircularProgress style={{ width: "16px", height: "16px", color: "white", }} /> </button>
                                : <button className='btn mt-3 mt-sm-4 w-100 ml-2' onClick={() => putOnMarketPlace()}>Confirm</button>
                        }

                    </S.ModalActions>
                </S.ModalBody>
            </Modal>
            {/* Unlist on Marketplace Modal */}
            <Modal
                isOpen={showUnlistMarketPlace}
                onRequestClose={() => setShowUnlistMarketPlace(false)}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: modalOverlayBgColor,
                        zIndex: 99,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '500px',
                        backgroundColor: modalBgColor,
                        color: modalTextColor,
                        borderRadius: '20px',
                        zIndex: 9999
                    },
                }}
            >
                <S.ModalBody>
                    <S.ModalHeader>
                        <S.ModalCloseIcon size={32} onClick={() => setShowUnlistMarketPlace(false)} />
                    </S.ModalHeader>
                    <S.ModalTitle>
                        Unlist Item
                        <S.PayAmount>
                            <S.Price>Are you sure you want to unlist this item?</S.Price>
                        </S.PayAmount>
                    </S.ModalTitle>
                    <S.ModalActions>
                        <button className="btn btn-bordered mt-3 mt-sm-4 w-100 mr-4" onClick={() => setShowUnlistMarketPlace(false)}>Cancel</button>
                        {
                            delistingStatus ? <button className='btn btn-solid-green mt-3 mt-sm-4 w-100 ml-2'>
                                <CircularProgress style={{ width: "16px", height: "16px", color: "white", }} /></button>
                                : <button className='btn btn-solid-green mt-3 mt-sm-4 w-100 ml-2' onClick={() => unlistItem(pairOwned)}>Unlist</button>
                        }

                    </S.ModalActions>
                </S.ModalBody>
            </Modal>
            {/* End Auction Modal */}
            <Modal
                isOpen={showEndAuction}
                onRequestClose={() => setShowEndAuction(false)}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: modalOverlayBgColor,
                        zIndex: 99,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '500px',
                        backgroundColor: modalBgColor,
                        color: modalTextColor,
                        borderRadius: '20px',
                        zIndex: 9999
                    },
                }}
            >
                <S.ModalBody>
                    <S.ModalHeader>
                        <S.ModalCloseIcon size={32} onClick={() => setShowEndAuction(false)} />
                    </S.ModalHeader>
                    <S.ModalTitle>
                        End Auction
                        <S.PayAmount>
                            <S.Price>Are you sure you want to end this auction ?</S.Price>
                        </S.PayAmount>
                    </S.ModalTitle>
                    <S.ModalActions>
                        <button className="btn btn-bordered mt-3 mt-sm-4 w-100 mr-4 " onClick={() => setShowEndAuction(false)}>Cancel</button>
                        {
                            endingAuctionStatus ? <button className="btn btn-solid-green mt-3 mt-sm-4 w-100">
                                <CircularProgress style={{ width: "16px", height: "16px", color: "white", }} /></button>
                                : <button className="btn btn-solid-green mt-3 mt-sm-4 w-100" onClick={() => endAuction()}>End Auction</button>
                        }
                    </S.ModalActions>
                </S.ModalBody>
            </Modal>
            {/* Buy Now Modal */}
            <Modal
                isOpen={showBuyNowModal}
                onRequestClose={() => closeBuyModal()}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: modalOverlayBgColor,
                        zIndex: 99
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '500px',
                        backgroundColor: modalBgColor,
                        color: modalTextColor,
                        borderRadius: '20px',
                        zIndex: 9999
                    },
                }}
            >
                <S.ModalBody>
                    <S.ModalHeader>
                        <S.ModalCloseIcon size={32} onClick={() => closeBuyModal()} />
                    </S.ModalHeader>
                    <S.ModalTitle>
                        <S.ModalLabel>You will pay</S.ModalLabel>
                        <S.PayAmount>
                            {/* <S.CoinImage src={"/img/logo.png"} /> */}
                            <S.Price>{prettyFormat(BigInt(currentPair?.price || 0) * BigInt(amount || 0), 18)}</S.Price>
                            <S.Unit>{APP_COIN}</S.Unit>
                        </S.PayAmount>
                    </S.ModalTitle>
                    <S.ModalRow>
                        <S.ModalLabel>Available</S.ModalLabel>
                        <S.ModalPrice>{parseFloat(balance).toFixed(3)} {APP_COIN}</S.ModalPrice>
                    </S.ModalRow>
                    {item?.type === 2 && (
                        <>
                            <S.ModalRow>
                                <S.ModalLabel>{`Amount (max: ${currentPair?.amount?.toString()})`}</S.ModalLabel>
                            </S.ModalRow>
                            <S.ModalRow>
                                <S.Input type={"number"} placeholder={`Enter Amount`} onChange={event => fixAmountValidator(event.target.value, currentPair?.Amount)}
                                    value={amount}
                                    onKeyPress={(event) => {
                                        if (!/^[0-9]*?$/.test(amount + event.key)) {
                                            event.preventDefault()
                                        }
                                    }} />
                            </S.ModalRow>
                        </>
                    )}
                    <S.ModalActions>
                        <button className="btn mt-3 mt-sm-4 btn-bordered w-100 mr-4" onClick={() => closeBuyModal()}>Cancel</button>
                        {
                            buyingStatus ? <button className="btn mt-3 mt-sm-4 btn-solid-green w-100">
                                <CircularProgress style={{ width: "16px", height: "16px", color: "white", }} /></button>
                                : <button className="btn mt-3 mt-sm-4 btn-solid-green w-100" onClick={() => buyItem(currentPair)}>Confirm</button>
                        }
                    </S.ModalActions>
                </S.ModalBody>
            </Modal>
            {/* Place a Bid Modal */}
            <Modal
                isOpen={showPlaceBidModal}
                onRequestClose={() => closePlaceBidModal()}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: modalOverlayBgColor,
                        zIndex: 99,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '500px',
                        backgroundColor: modalBgColor,
                        color: modalTextColor,
                        borderRadius: '20px',
                        zIndex: 9999
                    },
                }}
            >
                <S.ModalBody>
                    <S.ModalHeader>
                        <S.ModalCloseIcon size={32} onClick={() => closePlaceBidModal()} />
                    </S.ModalHeader>
                    <S.ModalTitle>Place Bid {item && item.name ? `on ${item.name}` : ''}</S.ModalTitle>
                    {highestBid ? (
                        <S.ModalRow>
                            <S.ModalLabel>Current bid</S.ModalLabel>
                            <S.ModalPrice>
                                {prettyFormat(highestBid.price, 18)} {APP_COIN}
                            </S.ModalPrice>
                        </S.ModalRow>
                    ) : (
                        <>
                            {firstAuction && (
                                <S.ModalRow>
                                    <S.ModalLabel>Start price</S.ModalLabel>
                                    <S.ModalPrice>
                                        {prettyFormat(firstAuction.startPrice, 18)} {APP_COIN}
                                    </S.ModalPrice>
                                </S.ModalRow>
                            )}
                        </>
                    )}
                    {item?.type === 2 && firstAuction && (
                        <S.ModalRow>
                            <S.ModalLabel>Amount</S.ModalLabel>
                            <S.ModalPrice>
                                {firstAuction.amount}
                            </S.ModalPrice>
                        </S.ModalRow>
                    )}
                    <S.BidPrice>
                        <S.ModalLabel>Your bid</S.ModalLabel>
                        <S.ModalMainPrice
                            type={"number"}
                            value={bidPrice}
                            onChange={event => bidPriceValidator(event.target.value)}
                            onKeyPress={(event) => {
                                if (!/^[0-9]*(\.[0-9]{0,2})?$/.test(bidPrice + event.key)) {
                                    event.preventDefault()
                                }
                            }}
                        />
                        <S.UnitContainer>
                            {/* <S.CoinImage src={"/img/logo.png"} /> */}
                            <S.Unit>{APP_COIN}</S.Unit>
                        </S.UnitContainer>
                    </S.BidPrice>
                    {firstAuction && (
                        <S.ModalRow>
                            <S.ModalLabel>You pay</S.ModalLabel>
                            <S.ModalPrice>
                                {prettyFormat(BigInt(bidPrice * (10 ** 18)) + BigInt(highestBid ?? firstAuction.startPrice), 18)} {APP_COIN}
                            </S.ModalPrice>
                        </S.ModalRow>
                    )}
                    <S.ModalRow>
                        <S.ModalLabel>Available</S.ModalLabel>
                        <S.ModalPrice>{parseFloat(balance).toFixed(3)} {APP_COIN}</S.ModalPrice>
                    </S.ModalRow>
                    <S.ModalActions>
                        <button className="btn mt-3 mt-sm-4 w-100 mr-4 btn-bordered" onClick={() => closePlaceBidModal()}>Cancel</button>
                        {
                            biddingStatus ? <button className="btn mt-3 mt-sm-4 w-100 btn-solid-green">
                                <CircularProgress style={{ width: "16px", height: "16px", color: "white", }} /></button>
                                : <button className="btn mt-3 mt-sm-4 w-100 btn-solid-green" onClick={() => placeBid()}>Place a Bid</button>
                        }
                    </S.ModalActions>
                </S.ModalBody>
            </Modal>
            {/* Notification */}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseDialog}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseDialog}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </section >
    )
}

export default ItemDetails
