import React, { Component } from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Activities from '../components/Activity/Activity';

class Activity extends Component {
    render() {
        return (
            <div className="main">
                <Breadcrumb title="Activity" subpage="Pages" page="Activity" />
                <Activities />
            </div>
        );
    }
}

export default Activity;