import React from 'react';

import ItemDetail from '../components/ItemDetails/ItemDetails';

const ItemDetails = (props) => {
    return (
        <div className="main">
            <ItemDetail {...props} />
        </div>
    );
}

export default ItemDetails;