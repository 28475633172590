import React from 'react';
import { Link } from "react-router-dom";
import { useWeb3React } from '@web3-react/core';
import mainLogo from '../../assets/img/nftnation_logo.png';

const Header = (props) => {
    const { account } = useWeb3React();

    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand navbar-sticky">
                <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-sticky" src={mainLogo} alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar */}
                    <ul className="navbar-nav items mx-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link" href='#'>Explore <i className="fas fa-angle-down ml-1" /></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item"><Link to="/explore/authors" className="nav-link">Authors</Link></li>
                                <li className="nav-item"><Link to="/explore/collections" className="nav-link">Collections</Link></li>
                                <li className="nav-item"><Link to="/explore/live-auctions" className="nav-link">Live Auctions</Link></li>
                                <li className="nav-item"><Link to="/explore/listed-items" className="nav-link">Listed Items</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to="/activity" className="nav-link">Activity</Link>
                        </li>
                    </ul>
                    {/* Navbar Icons */}
                    {/* <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href='#' className="nav-link" data-toggle="modal" data-target="#search">
                                <i className="fas fa-search" />
                            </a>
                        </li>
                    </ul> */}
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href='#' className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="fas fa-bars toggle-icon m-0" />
                            </a>
                        </li>
                    </ul>
                    {/* Navbar Action Button */}
                    <ul className="navbar-nav action">
                        {account ? (
                            <li className="nav-item">
                                <Link to={'/create'} className="btn ml-lg-auto btn-bordered-white"><i className="icon-note mr-2" />Create</Link>
                                <Link to={`/account/${account}`} className="btn ml-lg-auto btn-bordered-white"><i className="icon-wallet mr-md-2" />Account</Link>
                            </li>
                        ) : (
                            <li className="nav-item ml-3">
                                <div className="btn ml-lg-auto btn-bordered-white" onClick={props.connectAccount}><i className="icon-wallet mr-md-2" />Wallet Connect</div>
                            </li>
                        )}
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
